import React, { useEffect, useState, useRef } from "react";
import {

} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'


const styles = (theme) => ({
    ground: {
        width: '100%',
        height: '100%',
        padding: '16px 24px',
    },
});

function EmptyManagement(props) {
    const {
        classes,
        setSelectFlag,
    } = props



    useEffect(() => {
        setSelectFlag(routerNames.MasterManagement)
    }, [setSelectFlag])





    return (
        <div className={classes.ground}>
            
        </div >
    )
}

EmptyManagement.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(EmptyManagement);