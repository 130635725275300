import React, { useEffect, useState, useRef } from "react";
import {
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../function/sizeAdapter"

const styles = (theme) => ({
    navigationBar: {
        width: '100%',
        height: VH(24),
        fontSize: VW(20),
        fontWeight: 'bold',
        color: '#333',
        borderLeft: '6px solid #2E3ECF',
        paddingLeft: VW(8),
        display: 'flex',
        alignItems: 'center'
    }
});




function Navigation_bar_title(props) {
    const {
        classes,
        title_name,
        bottom
    } = props



    return (
        <div className={classes.navigationBar} style={{ marginBottom: bottom }}>{title_name}</div>
    )
}

Navigation_bar_title.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Navigation_bar_title);