import React, { useEffect, useState, useRef } from "react";
import { CaretDownOutlined } from '@ant-design/icons';
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Button, DatePicker, Input, Modal, Select } from 'antd';
import locale from "antd/es/date-picker/locale/ja_JP";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VH, VW } from "../function/sizeAdapter";
import { ApiAdminProjectAdd } from '../../network/api';
import Common_errorAlert from "../common/Common_errorAlert";
import log from "../function/log";
import AddIcon from "../icon/AddIcon";
import { errorMessage, formatCheck } from "../../wordsFile/wordsFile";


dayjs.extend(utc)
const { Option } = Select;

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    updateInput: {
        width: '100%',
        height: VH(74),
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        '& > div': {
            padding: '0 0.78vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16)
        },
        '& > div:nth-child(1)': {
            width: VW(160),
            height: '100%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(2)': {
            width: VW(848),
            height: '100%'
        },
        '& > div > Input': {
            width: '100%',
            height: VH(50),
            fontSize: VW(16)
        },
        '& > div > div:nth-child(1)': {
            width: "3%",
        },
        '& > div > div:nth-child(2)': {
            width: "22%",
            minHeight: VH(50)
        },
        '& > div > div:nth-child(3)': {
            fontSize: VW(16),
            color: '#AAA',
            marginLeft: VW(16)
        },
        '& > div > div > Input': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px",
            minHeight: VH(50)
        },
    },
    updateInputComplex: {
        width: '100%',
        '& > div:not(:last-child)': {
            width: '100%',
            height: VH(74),
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div:not(:last-child) > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16)
        },
        '& > div:not(:last-child) > div > Input': {
            width: '100%',
            height: VH(50),
            fontSize: VW(16)
        },
        '& > div:not(:last-child) > div:nth-child(1)': {
            width: VW(160),
            height: '100%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:last-child': {
            width: '100%',
            height: VH(50),
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: '1vw',
            '& > div': {
                cursor: 'pointer',
                color: theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '0.78vw',
            }
        },
    },
    InputAdministrator: {
        width: '100%',
        height: VH(74),
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16)
        },
        '& > div:nth-child(1)': {
            width: VW(128),
            height: '100%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
            padding: '0 0.78vw',
        },
        '& > div:nth-child(2)': {
            width: VW(128),
            padding: '0 0.39vw 0 0.78vw',
        },
        '& > div:nth-child(3)': {
            width: VW(128),
            padding: '0 0.78vw 0 0.39vw',
        },
        '& > div:nth-last-child(2)': {
            width: VW(160),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
            padding: '0 0.78vw',
        },
        '& > div:nth-child(5)': {
            width: VW(432),
            padding: '0 0.78vw',
        },
        '& > div > Input': {
            width: '100%',
            height: VH(50),
            fontSize: VW(16)
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function Project_add(props) {
    const {
        classes,
        open,
        onClose,
        administratorList,
        setAdministratorList,
        getList,
    } = props


    useEffect(() => {
        if (!open) {
            let nullList = [
                { first_name: '', last_name: '', email: '' },
                { first_name: '', last_name: '', email: '' },
                { first_name: '', last_name: '', email: '' }
            ]
            setAdministratorList([...nullList])
            setRrrorOpen(false)
            setStoreName('')
            setPostalCode('')
            setAddress('')
            setAccess('')
            setPhone('')
            setBusinessHours('')
            setHandlingGenre('')
            setHandlingBrand('')
        }
    }, [open])

    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // add 管理者
    const addAdministratorListItem = () => {
        administratorList.push({ first_name: '', last_name: '', email: '' })
        setAdministratorList([...administratorList])
    }


    // 会社名
    const [storeName, setStoreName] = useState('')
    // 管理者 list
    const updateAdministratorList = (item, index, flag) => {
        administratorList[index][flag] = item
        setAdministratorList([...administratorList])
        log(administratorList)
    }
    // 郵便番号
    const [postalCode, setPostalCode] = useState('')
    // 住所
    const [address, setAddress] = useState('')
    // アクセス情報
    const [access, setAccess] = useState('')
    // 電話番号
    const [phone, setPhone] = useState('')
    // 営業時間
    const [businessHours, setBusinessHours] = useState('')
    // 取り扱いジャンル
    const [handlingGenre, setHandlingGenre] = useState('')
    // 取り扱いブランド
    const [handlingBrand, setHandlingBrand] = useState('')



    const modalRef = useRef(null);
    // add api
    const addItem = () => {
        const modalNode = modalRef.current;
        modalNode.scrollIntoView({ block: "start" });
        let InformationFlag = false
        administratorList.map(item => {
            if ((item.first_name === '' && (item.last_name != '' || item.email != '')) || (item.last_name === '' && (item.first_name != '' || item.email != '')) || (item.email === '' && (item.last_name != '' || item.first_name != ''))) {
                InformationFlag = true
            }
        })
        if (storeName === '') {
            setErrorContent(errorMessage.storeNameNull)
            setRrrorOpen(true)
        }
        else if (InformationFlag) {
            setErrorContent(errorMessage.administratorNull)
            setRrrorOpen(true)
        } else if (phone != '' && !formatCheck.Phone.test(phone)) {
            setErrorContent(errorMessage.phoneFormatError)
            setRrrorOpen(true)
        } else if (postalCode != '' && !formatCheck.ZipCode.test(postalCode)) {
            setErrorContent(errorMessage.zipCodeFormatError)
            setRrrorOpen(true)
        } else {
            let userList = []
            administratorList.map(item => {
                if (item.first_name != '') {
                    userList.push(item)
                }
            })
            let params = {
                users: userList,
                project_name: storeName,
                postal_code: postalCode,
                project_address: address,
                access_information: access,
                phone: phone,
                business_hours: businessHours,
                handling_genre: handlingGenre,
                handling_brand: handlingBrand
            }
            log(params)
            ApiAdminProjectAdd(params).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    log(res.data)
                    if (res.data.message) {
                        setRrrorOpen(true)
                        setErrorContent(res.data.message)
                    } else {
                        getList('add')
                        onClose()
                    }
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div ref={modalRef}>
                <div className={classes.tableTitle}>
                    <AddIcon />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>店舗の登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.updateInput} style={{ marginTop: VH(13) }}>
                    <div>店舗名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: '0.5vw' }}>*</span></div>
                    <div>
                        <Input
                            value={storeName}
                            onChange={(event) => setStoreName(event.target.value)}
                            placeholder='店舗名を入力してください'
                            maxLength={60}
                        />
                    </div>
                </div>
                <div className={classes.updateInputComplex}>
                    {administratorList.map((item, index) => {
                        return (
                            <div className={classes.InputAdministrator} key={index}>
                                <div>管理者</div>
                                <div>
                                    <Input
                                        placeholder="姓"
                                        value={item.first_name}
                                        onChange={(event) => updateAdministratorList(event.target.value, index, 'first_name')}
                                        maxLength={30}
                                    />
                                </div>
                                <div>
                                    <Input
                                        placeholder="名"
                                        value={item.last_name}
                                        onChange={(event) => updateAdministratorList(event.target.value, index, 'last_name')}
                                        maxLength={30}
                                    />
                                </div>
                                <div>メールアドレス</div>
                                <div>
                                    <Input
                                        value={item.email}
                                        onChange={(event) => updateAdministratorList(event.target.value, index, 'email')}
                                        maxLength={200}
                                    />
                                </div>
                            </div>
                        )
                    })}
                    <div>
                        <div onClick={addAdministratorListItem} style={{ fontSize: VW(16) }}>
                            <AddIcon />&ensp;管理者を追加する
                        </div>
                    </div>
                </div>
                <div className={classes.updateInput}>
                    <div>郵便番号</div>
                    <div>
                        <div>〒</div>
                        <div>
                            <Input
                                placeholder="郵便番号"
                                value={postalCode}
                                onChange={(event) => setPostalCode(event.target.value)}
                                maxLength={8}
                            />
                        </div>
                        <div>※半角７桁ハイフンあり</div>
                    </div>
                </div>
                <div className={classes.updateInput}>
                    <div>住所</div>
                    <div>
                        <Input
                            value={address}
                            onChange={(event) => setAddress(event.target.value)}
                            placeholder='住所を入力してください'
                            maxLength={50}
                        />
                    </div>
                </div>
                <div className={classes.updateInput}>
                    <div>アクセス情報</div>
                    <div>
                        <Input
                            value={access}
                            onChange={(event) => setAccess(event.target.value)}
                            placeholder='例：地下鉄〇〇駅△△出口から徒歩5分／△△バス停の目の前／□□交差点を右折してすぐ'
                            maxLength={50}
                        />
                    </div>
                </div>
                <div className={classes.updateInput}>
                    <div>電話番号</div>
                    <div>
                        <Input
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)}
                            placeholder='000-0000-0000'
                            maxLength={15}
                        />
                    </div>
                </div>
                <div className={classes.updateInput}>
                    <div>営業時間</div>
                    <div>
                        <Input
                            value={businessHours}
                            onChange={(event) => setBusinessHours(event.target.value)}
                            placeholder='例：10:00-16:00（毎週火曜日は定休日）'
                            maxLength={50}
                        />
                    </div>
                </div>
                <div className={classes.updateInput}>
                    <div>取り扱いジャンル</div>
                    <div>
                        <Input
                            value={handlingGenre}
                            onChange={(event) => setHandlingGenre(event.target.value)}
                            placeholder='例：スノーボード/サーフィンなど、ボードのことならお任せください'
                            maxLength={50}
                        />
                    </div>
                </div>
                <div className={classes.updateInput} style={{ borderBottom: '1px solid #EBE2E2' }}>
                    <div>取り扱いブランド</div>
                    <div>
                        <Input
                            value={handlingBrand}
                            onChange={(event) => setHandlingBrand(event.target.value)}
                            placeholder='例：人気の〇〇など多数取り扱っております'
                            maxLength={50}
                        />
                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button onClick={addItem} type='primary'>登録</Button>
                </div>
            </div>
        </Modal>
    )
}

Project_add.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Project_add);