import { ClearRounded } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import {
    MenuItem,
    Select,
    TablePagination
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Button, Input } from 'antd';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Common_checkBoxTable from "../../componets/common/Common_checkBoxTable";
import Common_errorAlert from '../../componets/common/Common_errorAlert';
import Common_pagenation from '../../componets/common/Common_pagination';
import Common_successAlert from "../../componets/common/Common_successAlert";
import Tenant_add from "../../componets/dialog/Tenant_add";
import Tenant_delete from "../../componets/dialog/Tenant_delete";
import log from "../../componets/function/log";
import { VH, VW } from "../../componets/function/sizeAdapter";
import dic from "../../componets/function/utc";
import HeaderBarIcon from "../../componets/icon/HeaderBarIcon";
import { ApiTenantList } from '../../network/api';
import { routerNames } from '../../wordsFile/wordsFile';
import Navigation_bar_router from "../../componets/common/Navigation_bar_router";
import Navigation_bar_title from "../../componets/common/Navigation_bar_title";


const styles = (theme) => ({
    ground: {
        width: '100%',
        userSelect: 'none',
        position: 'relative',
        padding: '48px 24px',
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8)
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    retrievalBlock: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#F5F5F5',
        // paddingLeft: VW(16),
        paddingRight: VW(16),
        paddingTop: VH(16),
        '& > Input': {
            width: VW(874),
            height: VH(40),
            fontSize: VW(16)
        },
        '& > Button': {
            width: VW(120),
            height: VH(40),
            fontSize: VW(16),
            fontSize: VW(16),
            backgroundColor: theme.palette.primary.main,
        }
    },
    inputTitle: {
        fontSize: VW(14),
        width: VW(100),
        maxWidth: VW(85),
        textAlign: 'right',
        marginRight: VW(16)
    },
    iconContainer: {
        color: '#999',
    },
    AddDeleteBlock: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: VH(24),
        '& > Button': {
            marginLeft: VW(8),
            width: VW(120),
            height: VH(40),
            fontSize: VW(16),
        },
        '& > Button:nth-of-type(1)': {
            color: '#E60024',
        },
        '& > Button:nth-of-type(2)': {
            fontWeight: 'bold',
            backgroundColor: theme.palette.primary.main,
        }
    },
    tableBlock: {
        marginRight: VW(24),
        paddingTop: '1vh'
    },
    querySelect: {
        width: VW(160),
        height: VH(40),
        fontSize: VW(16),
        backgroundColor: '#FFF'
    },
    toolbar: {
        "& > p:nth-of-type(2)": {
            fontSize: VW(12),
            color: "#333333",
            fontWeight: 400
        },

    },
    selectLabel: {
        "& .MuiTablePagination-selectLabel": {
            fontSize: VW(12),
            color: "#333333",
            fontWeight: 400
        }
    }
});

function TenantManagement(props) {
    const {
        classes,
        setSelectFlag
    } = props


    useEffect(() => {
        setSelectFlag(routerNames.TenantManagement)
    }, [setSelectFlag])


    var authority_id = localStorage.getItem("authority_id")

    const navigate = useNavigate()

    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // add error email
    const [errorEmailOpen, setErrorEmailOpen] = useState(false)
    const [errorEmailContent, setErrorEmailContent] = useState('')


    // list api
    const getList = (errorEmail) => {
        if (localStorage.getItem('deleteFlag')) {
            setSuccessContent('削除が完了しました。')
            setSuccessOpen(true)
            localStorage.removeItem('deleteFlag')
        }
        if (errorEmail && errorEmail != 'add' && errorEmail != 'delete') {
            let errConcent = ''
            errorEmail.map(errorItem => {
                errConcent = errConcent + errorItem.email + '\n'
            })
            setSuccessOpen(false)
            setErrorEmailContent(errConcent)
            setErrorEmailOpen(true)
        }
        let params = {
            query_content: searchParam,
            pageNum: page + 1,
            pageLimit: rowsPerPage,
            sortKey: pagingFlag.current,
            sortValue: pagingDirection.current === '↓' ? 'asc' : 'desc',
            loginStatus: searchAdministratorFlag,
            tenantContractStatus: searchtenantContractFlag
        }
        ApiTenantList(params).then(res => {
            let resData = res.data
            if (res.status === 200) {
                document.getElementById('father_Block').scrollTop = 0;
                localStorage.setItem('tenant_keywordSearch', keywordSearch)
                localStorage.setItem('tenant_loginStatus', administratorFlag)
                localStorage.setItem('tenant_tenantContractFlag', tenantContractFlag)
                log(resData)
                setRrrorOpen(false)
                setCount(resData.count)
                resData.data.map(item => {
                    item.login_status = item.login_status === '3' ? 'correct' : item.login_status === '2' ? 'wrong' : ''
                    item.representative = (item.user_master.length > 0 ? item.user_master[0].surname + item.user_master[0].name : '') + (item.user_master.length > 1 ? '（他' + (item.user_master.length - 1) + '名）' : '')
                    item.email = item.user_master.length > 0 ? item.user_master[0].email : ''
                    item.create_date = dic.UTCDateToLocalDate(item.create_date)
                    // item.tenant_contract_date_start = item.tenant_contract_date_start ? dic.UTCDateToLocalDate(item.tenant_contract_date_start) : ''
                    item.tenant_contract_date_end = item.tenant_contract_date_end ? dic.UTCDateToLocalDate(item.tenant_contract_date_end) : ''
                })
                setBodyList([...resData.data])
                if (errorEmail === 'add') {
                    setSuccessContent('登録が完了しました。')
                    setSuccessOpen(true)
                }
                if (errorEmail === 'delete') {
                    setSuccessContent('削除が完了しました。')
                    setSuccessOpen(true)
                }
                setDeleteData([])
                setIndeterminate(false)
                setCheckAll(false)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  アプリケーション
    // const [appList, setAppList] = useState([]);
    // const [selectedApp, setSelectedApp] = useState([]);
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  アプリケーション情報取得
    // const getAppList = () => {
    //     ApiApplicationList().then(res => {
    //         let resData = res.data
    //         if (res.status === 200) {
    //             setAppList(resData.data)
    //         }
    //         else if (res.status >= 300 && res.status < 500 && res.status != 401) {
    //             log(res)
    //             setRrrorOpen(true)
    //             setErrorContent(res.data.detail.toString())
    //         }
    //     }).catch(err => {

    //     })
    // }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  アプリケーション情報取得
    // const handleCheckboxChange = (appId) => {
    //     const newSelectedApp = [...selectedApp];
    //     const index = newSelectedApp.indexOf(appId);

    //     if (!appId) {
    //         newSelectedApp.length = 0
    //         if (index < 0) {
    //             appList.map((app) => {
    //                 newSelectedApp.push(app.application_id)
    //             })
    //         }
    //     }
    //     if (index < 0) {
    //         newSelectedApp.push(appId);
    //     }
    //     else {
    //         const indexZero = newSelectedApp.indexOf(0);
    //         if (!(indexZero < 0)) {
    //             newSelectedApp.splice(indexZero, 1);
    //         }
    //         newSelectedApp.splice(index, 1);
    //     }

    //     setSelectedApp(newSelectedApp)
    // };

    // 検索
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  キーワード input
    const [keywordSearch, setKeywordSearch] = useState(localStorage.getItem('tenant_keywordSearch') == null || localStorage.getItem('tenant_keywordSearch') == undefined ? '' : localStorage.getItem('tenant_keywordSearch'))
    const [searchParam, setSearchParam] = useState(localStorage.getItem('tenant_searchParam') == null || localStorage.getItem('tenant_searchParam') == undefined ? '' : localStorage.getItem('tenant_searchParam'))
    const [searchAdministratorFlag, setSearchAdministratorFlag] = useState(localStorage.getItem('tenant_searchAdministratorFlag') ? '0' : localStorage.getItem('tenant_searchAdministratorFlag'))
    const [searchtenantContractFlag, setSearchtenantContractFlag] = useState(!localStorage.getItem('tenant_searchtenantContractFlag') ? '0' : localStorage.getItem('tenant_searchtenantContractFlag'))
    const search = () => {
        setSearchParam(keywordSearch)
        setSearchAdministratorFlag(administratorFlag)
        setSearchtenantContractFlag(tenantContractFlag)
        setPage(0)
        setCurrentPage(0)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  招待 select 
    const [administratorFlagOption, setAdministratorFlagOption] = useState([
        { value: '0', label: 'すべて' },
        { value: '1', label: '未招待' },
        { value: '2', label: '招待済（招待のみで登録してない）' },
        { value: '3', label: '登録済' }
    ])
    const [administratorFlag, setAdministratorFlag] = useState(!localStorage.getItem('tenant_loginStatus') ? '0' : localStorage.getItem('tenant_loginStatus'))
    const selectAdministratorFlag = (e) => {
        setAdministratorFlag(e.target.value);
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  契約 select 
    const [tenantContractFlagOption, settenantContractFlagOption] = useState([
        { value: '0', label: 'すべて' },
        { value: '1', label: 'あり' },
        { value: '2', label: 'なし' },
    ])
    const [tenantContractFlag, settenantContractFlag] = useState(!localStorage.getItem('tenant_tenantContractFlag') ? '0' : localStorage.getItem('tenant_tenantContractFlag'))
    const selecttenantContractFlag = (e) => {
        settenantContractFlag(e.target.value);
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  検索 button
    const retrieval = () => {
        getList()
    }






    // Common_checkBoxTable
    const [titleList, setTitleList] = useState([
        { titleIcon: '↓', name: 'テナントCD', field: 'tenant_id', width: '13.4199134199134%' },
        { titleIcon: '↓', name: 'テナント名', field: 'tenant_name', width: '25.1082251082251%', canClick: true },
        { titleIcon: '↓', name: '招待', field: 'login_status', width: '6.0606060606061%', paddingLeft: '0.5vw' },
        { titleIcon: '↓', name: '契約', field: 'tenant_contract_status', width: '9.5238095238095%' },
        { titleIcon: '', name: '管理者', field: 'representative', width: '19.9134199134199%' },
        { titleIcon: '', name: 'メールアドレス', field: 'email', width: '25.974025974026%' }
    ])
    const [bodyList, setBodyList] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  update
    const getItemData = (item) => {
        log(item)
        navigate(routerNames.TenantManagementDetail, { state: item })
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete
    const [deleteData, setDeleteData] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  sort
    const [count, setCount] = useState(0)
    const pagingFlag = useRef('tenant_id')
    const pagingDirection = useRef('↑')
    const toSort = (item) => {
        log(item)
        pagingFlag.current = item.field
        pagingDirection.current = item.titleIcon
        titleList.map(titleItem => {
            if (titleItem.name === item.name) {
                titleItem.titleIcon = item.titleIcon === '↓' ? '↑' : '↓'
                titleItem.checked = true
            } else {
                titleItem.checked = false
                if (titleItem.field === 'representative' || titleItem.field === 'email') {
                    titleItem.titleIcon = ''
                } else {
                    titleItem.titleIcon = '↓'
                }
            }
        })
        setTitleList([...titleList])
        getList()
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  paging
    const [page, setPage] = useState(localStorage.getItem('tenant_page') ? Number(localStorage.getItem('tenant_page')) : 0);
    const [currentPage, setCurrentPage] = useState(localStorage.getItem('tenant_page') ? Number(localStorage.getItem('tenant_page')) : 0);
    const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('tenant_rowsPerPage') ? Number(localStorage.getItem('tenant_rowsPerPage')) : 50);
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        setCurrentPage(newPage)
        localStorage.setItem('tenant_page', String(newPage))
    };
    const handleChangePageForPagination = (event, newPage) => {
        const currentPage = newPage - 1
        setPage(currentPage)
        setCurrentPage(currentPage)
        localStorage.setItem('tenant_page', String(currentPage))
    };
    const handleChangeRowsPerPage = (event) => {
        localStorage.setItem('tenant_rowsPerPage', String(event.target.value))
        setRowsPerPage(event.target.value);
        setPage(0);
        localStorage.setItem('tenant_page', '0')
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  checkbox check
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);





    // dialog
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteOpen(true)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  add dialog
    const [addOpen, setAddOpen] = useState(false)
    const addOnClose = () => {
        setAddOpen(false)
    }
    const openAddDialog = () => {
        setAddOpen(true)
        //getAppList()
    }
    const [administratorList, setAdministratorList] = useState([
        { name: '', pseudonym: '', email: '' },
        { name: '', pseudonym: '', email: '' },
        { name: '', pseudonym: '', email: '' }
    ])



    // get list
    useEffect(() => {
        getList()
    }, [page, rowsPerPage, searchParam, searchAdministratorFlag, searchtenantContractFlag])


    return (
        <div className={classes.ground}>
            <Navigation_bar_router main_name='テナント一覧' />
            <Navigation_bar_title title_name='テナント一覧' bottom="12px" />
            <Common_errorAlert
                open={errorEmailOpen}
                setRrrorOpen={setErrorEmailOpen}
                errorContent={errorEmailContent}
                border
            />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
            />
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
            />
            <div style={{ marginTop: VH(12) }}>
                <div className={classes.retrievalBlock}>
                    <div className={classes.inputTitle}>キーワード</div>
                    <Input
                        placeholder='キーワードを入力してください'
                        value={keywordSearch}
                        style={{
                            // width: VW(874),
                            height: VH(40),
                            fontSize: VW(16)
                        }}
                        onChange={(event) => { setKeywordSearch(event.target.value) }}
                        suffix={
                            (
                                <ClearRounded className={classes.iconContainer} style={{ display: keywordSearch ? '' : 'none' }} onClick={(e) => {
                                    setKeywordSearch('')
                                }}></ClearRounded>
                            )
                        }
                    />
                </div>
                <div className={classes.retrievalBlock} style={{ paddingBottom: VH(16) }}>
                    <div className={classes.inputTitle}>&emsp;&emsp;&emsp;招待</div>
                    <Select
                        className={classes.querySelect}
                        value={administratorFlag}
                        label=""
                        onChange={selectAdministratorFlag}
                    >
                        {administratorFlagOption.map((item, index) => {
                            return (
                                <MenuItem style={{ fontSize: VW(16) }} key={index} value={item.value}>{item.label}</MenuItem>
                            )
                        })}
                    </Select>
                    <div className={classes.inputTitle} style={{ marginLeft: VW(16) }}>契約</div>
                    <Select
                        className={classes.querySelect}
                        value={tenantContractFlag}
                        label=""
                        onChange={selecttenantContractFlag}
                    >
                        {tenantContractFlagOption.map((item, index) => {
                            return (
                                <MenuItem style={{ fontSize: VW(16) }} key={index} value={item.value}>{item.label}</MenuItem>
                            )
                        })}
                    </Select>
                    <Button type="primary" icon={<SearchIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={search} style={{ position: 'absolute', right: VW(16), fontWeight: 'bold' }}>検索</Button>
                </div>
            </div>
            <div className={classes.AddDeleteBlock}>
                <Button style={{ padding: 0 }} type="text" danger icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openDeleteDialog}>削除</Button>
                <Button style={{ padding: 0 }} type="primary" icon={<AddIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openAddDialog}>登録</Button>
            </div>
            <TablePagination
                style={{ fontSize: VW(12) }}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[20, 50, 100]}
                labelRowsPerPage='表示件数:'
                className={classes.selectLabel}
                classes={{
                    toolbar: classes.toolbar
                }}
            />
            <div className={classes.tableBlock}>
                <Common_checkBoxTable
                    titleList={titleList}
                    bodyList={bodyList}
                    setBodyList={setBodyList}
                    getItemData={getItemData}
                    setCheckList={setDeleteData}
                    toSort={toSort}
                    indeterminate={indeterminate}
                    setIndeterminate={setIndeterminate}
                    checkAll={checkAll}
                    setCheckAll={setCheckAll}
                />
            </div>
            <Tenant_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={deleteData}
                setDeleteData={setDeleteData}
                getList={getList}
                setIndeterminate={setIndeterminate}
                setCheckAll={setCheckAll}
            />
            <Tenant_add
                open={addOpen}
                onClose={addOnClose}
                administratorList={administratorList}
                setAdministratorList={setAdministratorList}
                getList={getList}
            // selectedApp={selectedApp}
            // setSelectedApp={setSelectedApp}
            // appList={appList}
            // handleCheckboxChange={handleCheckboxChange}
            />
            <Common_pagenation
                count={count}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onChange={handleChangePageForPagination}
            />
        </div>
    )
}

TenantManagement.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(TenantManagement); 