import { withStyles } from "@mui/styles";
import { Button } from 'antd';
import React from "react";
import { VH, VW } from "../function/sizeAdapter";
import { ApiAdminProjectReinvite } from '../../network/api';
import log from "../function/log";



const styles = (theme) => ({
    contentBlock: {
        width: '100%',
        marginTop: VH(12),
        '& > div': {
            height: VH(74),
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',
        },
        '& > div > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div > div:nth-child(1)': {
            width: '16%',
            height: '100%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(1) > div:nth-child(2)': {
            width: '84%',
            height: '100%'
        },
        '& > div:nth-child(2) > div:nth-child(2)': {
            width: '82%',
            height: '100%'
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`
        },
        '& > div:last-child > div:nth-child(2)': {
            width: '82%',
            height: '100%'
        },
    },
    administratorBlock: {
        '& > div:nth-child(2)': {
            width: '16%',
        },
        '& > div:nth-child(3)': {
            width: '16%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
            overflow: 'hidden'
        },
        '& > div:nth-child(4)': {
            width: '34%',
            overflow: 'hidden'
        },
        '& > div:nth-child(5)': {
            right: 0,
            fontSize: VW(16)
        },
        '& > div:nth-child(6)': {
            justifyContent: 'center',
            marginLeft: VW(16),
            padding: '0',
            '& > Button': {
                display: 'flex',
                right: 0,
                alignItems: 'center',
                justifyContent: 'center',
                width: VW(64),
                height: VH(32),
                backgroundColor: theme.palette.primary.main,
                fontSize: VW(14),
                fontWeight: 'bold'
            }
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
});

function Project_item(props) {
    const {
        classes,
        detailData,
        setRrrorOpen,
        setErrorContent,
        setSuccessOpen,
        setSuccessContent
    } = props


    // send email api
    const sengEmailAgain = (item) => {
        log(item)
        let param = {
            email: item
        }
        ApiAdminProjectReinvite(param).then(res => {
            if (res.status === 200) {
                log(res.data)
                setRrrorOpen(false)
                setSuccessOpen(true)
                setSuccessContent(res.data.data)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }


    return (
        <div className={classes.contentBlock}>
            <div>
                <div>店舗CD</div>
                <div>{detailData.project_id}</div>
            </div>
            <div>
                <div>店舗名</div>
                <div>{detailData.project_name}</div>
            </div>
            {detailData.project_manager && detailData.project_manager.map((item, index) => {
                return (
                    <div key={index} className={classes.administratorBlock}>
                        <div>管理者</div>
                        <div>
                            <p>{item.first_name + item.last_name}</p>
                        </div>
                        <div>メールアドレス</div>
                        <div>
                            <p>{item.email}</p>
                        </div>
                        <div style={{ color: item.status_id === 1 ? '#0052CC' : '#AAAAAA' }}>
                            {item.status_id === 1 ? '招待中' : '完了'}
                        </div>
                        {item.status_id === 1 &&
                            <div>
                                <Button type="primary" onClick={() => { sengEmailAgain(item.email) }}>再送信</Button>
                            </div>
                        }
                    </div>
                )
            })}
            <div>
                <div>郵便番号</div>
                <div>{detailData.postal_code ? '〒 ' + detailData.postal_code : detailData.postal_code}</div>
            </div>
            <div>
                <div>住所</div>
                <div>{detailData.project_address}</div>
            </div>
            <div>
                <div>アクセス情報</div>
                <div>{detailData.access_information}</div>
            </div>
            <div>
                <div>電話番号</div>
                <div>{detailData.phone}</div>
            </div>
            <div>
                <div>営業時間</div>
                <div>{detailData.business_hours}</div>
            </div>
            <div>
                <div>取り扱いジャンル</div>
                <div>{detailData.handling_genre}</div>
            </div>
            <div>
                <div>取り扱いブランド</div>
                <div>{detailData.handling_brand}</div>
            </div>
            <div>
                <div>登録日</div>
                <div>{detailData.create_date}</div>
            </div>
        </div>
    )
}

Project_item.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Project_item);