import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Button } from 'antd';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import Common_successAlert from "../../componets/common/Common_successAlert";
import Project_delete from "../../componets/dialog/Project_delete";
import log from "../../componets/function/log";
import { VH, VW } from "../../componets/function/sizeAdapter";
import { errorMessage, formatCheck, routerNames } from '../../wordsFile/wordsFile';
import Navigation_bar_router from "../../componets/common/Navigation_bar_router";
import Navigation_bar_title from '../../componets/common/Navigation_bar_title';
import { ApiAdminProjectDetail, ApiAdminProjectUpdate } from '../../network/api'
import ProjectManagement_item from '../../componets/HtmlBody/ProjectManagement_item';
import ProjectManagement_input from '../../componets/HtmlBody/ProjectManagement_input';



dayjs.extend(utc)
const styles = (theme) => ({
    ground: {
        width: '100%',
        userSelect: 'none',
        position: 'relative',
        padding: '48px 24px',
    },
    tableTitle: {
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    updateDeleteBlock: {
        position: 'absolute',
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& > Button': {
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& > Button:nth-child(2)': {
            color: '#E60024',
            marginLeft: VW(24)
        }
    },
    bottomButton: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VW(40),
        marginBottom: VW(40),
        '& > Button': {
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function ProjectManagementDetail(props) {
    const {
        classes,
        setSelectFlag,
    } = props


    useEffect(() => {
        setSelectFlag(routerNames.ProjectManagement)
        log(state)
        getDetailData()
    }, [setSelectFlag])

    const location = useLocation();
    const { state } = location;


    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteOpen(true)
    }


    // update flag
    const [updateFlag, setUpdateFlag] = useState(false)



    // detailed list data
    const [detailData, setDetailData] = useState(state)
    const changeDetailData = (value, key) => {
        detailData[key] = value
        setDetailData({ ...detailData })
        log(detailData)
    }



    // 管理者 list
    const [administratorList, setAdministratorList] = useState([])
    // 管理者追加
    const addAdministratorListItem = () => {
        administratorList.push({ first_name: '', last_name: '', email: '' })
        setAdministratorList([...administratorList])
    }
    // update list
    const updateAdministratorList = (item, index, flag) => {
        administratorList[index][flag] = item
        setAdministratorList([...administratorList])
        log(administratorList)
    }


    // detail api
    const getDetailData = (flag) => {
        let param = {
            project_id: state.project_id
        }
        console.log(state.project_id);
        ApiAdminProjectDetail(param).then(resDetail => {
            let detail = resDetail.data
            if (resDetail.status == 200) {
                log(detail)
                detail.data.login_status = detail.data.login_status === '3' ? 'correct' : detail.data.login_status === '2' ? 'wrong' : ''
                detail.data.representative = (detail.data.project_manager.length > 0 ? detail.data.project_manager[0].first_name + detail.data.project_manager[0].last_name : '') + (detail.data.project_manager.length > 1 ? '（他' + (detail.data.project_manager.length - 1) + '名）' : '')
                detail.data.email = detail.data.project_manager.length > 0 ? detail.data.project_manager[0].email : ''
                setDetailData({ ...detail.data })
                if (flag == 'alert') {
                    setRrrorOpen(false)
                    setSuccessOpen(true)
                    setSuccessContent('更新が完了しました。')
                    setUpdateFlag(false)
                }
            } else if (resDetail.status >= 300 && resDetail.status < 500 && resDetail.status != 401) {
                log(detail)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(resDetail.data.detail.toString())
            }
        }).catch(errDetail => {

        })
    }


    // update api
    const toUpdate = () => {
        document.getElementById('scroll_Block').scrollTop = 0;
        let InformationFlag = false
        administratorList.map(item => {
            if ((item.first_name === '' && (item.last_name != '' || item.email != '')) || (item.last_name === '' && (item.first_name != '' || item.email != '')) || (item.email === '' && (item.last_name != '' || item.first_name != ''))) {
                InformationFlag = true
            }
        })
        if (detailData.project_name === '') {
            setErrorContent(errorMessage.storeNameNull)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else if (InformationFlag) {
            setErrorContent(errorMessage.administratorNull)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else if (detailData.phone != '' && !formatCheck.Phone.test(detailData.phone)) {
            setErrorContent(errorMessage.phoneFormatError)
            setRrrorOpen(true)
        } else if (detailData.postal_code != '' && !formatCheck.ZipCode.test(detailData.postal_code)) {
            setErrorContent(errorMessage.zipCodeFormatError)
            setRrrorOpen(true)
        } else {
            let userList = []
            administratorList.map(item => {
                if (item.first_name != '') {
                    userList.push(item)
                }
            })
            let params = Object.assign({}, detailData)
            params.users = userList
            delete params.project_manager
            delete params.create_date
            delete params.login_status
            delete params.representative
            delete params.email
            log(params)
            ApiAdminProjectUpdate(params).then(res => {
                if (res.status === 200) {
                    log(res.data)
                    if (res.data.message) {
                        setSuccessOpen(false)
                        setRrrorOpen(true)
                        setErrorContent(res.data.message)
                    } else {
                        setUpdateFlag(false)
                        getDetailData('alert')
                    }
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }

    useEffect(() => {
        if (!updateFlag) {
            setAdministratorList([])
        } else {
            setSuccessOpen(false)
        }
    }, [updateFlag])



    // キャンセル
    const backItem = () => {
        setUpdateFlag(false)
        getDetailData()
    }



    return (
        <div className={classes.ground}>
            <Navigation_bar_router main_name='店舗一覧' secondary_name='店舗情報' />
            <div className={classes.tableTitle}>
                <Navigation_bar_title title_name={updateFlag ? '店舗情報の編集' : '店舗情報'} bottom='12px' />
                <div className={classes.updateDeleteBlock}>
                    {!updateFlag &&
                        <>
                            <Button style={{ padding: 0 }} type="text" icon={<CreateIcon style={{ width: VW(24), height: VH(24) }} />} onClick={() => { setUpdateFlag(true) }}>編集</Button>
                            {localStorage.getItem('authority_id') == 2 && <Button style={{ padding: 0 }} type="text" danger icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24) }} />} onClick={openDeleteDialog}>削除</Button>}
                        </>
                    }
                </div>
            </div>
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
            />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
            />
            {
                !updateFlag ?
                    <ProjectManagement_item
                        detailData={detailData}
                        setRrrorOpen={setRrrorOpen}
                        setErrorContent={setErrorContent}
                        setSuccessOpen={setSuccessOpen}
                        setSuccessContent={setSuccessContent}
                    />
                    :
                    <ProjectManagement_input
                        detailData={detailData}
                        changeDetailData={changeDetailData}
                        administratorList={administratorList}
                        updateAdministratorList={updateAdministratorList}
                        addAdministratorListItem={addAdministratorListItem}
                    />
            }
            <div className={classes.bottomButton}>
                {!updateFlag ?
                    <Button onClick={() => { window.history.back() }}>店舗一覧に戻る</Button>
                    :
                    <>
                        <Button onClick={backItem}>キャンセル</Button>
                        <Button type='primary' onClick={toUpdate}>更新</Button>
                    </>
                }
            </div>
            <Project_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={[detailData]}
                historyFlag={true}
            />
        </div >
    )
}

ProjectManagementDetail.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(ProjectManagementDetail);