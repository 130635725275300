import { CaretDownOutlined } from '@ant-design/icons';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
    Collapse,
    List,
    ListItemButton
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Button, Select } from 'antd';
import qs from 'qs';
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ApiAdminAuthorityList, ApiUserUserauthority } from "../../network/api";
import { routerNames } from '../../wordsFile/wordsFile';
import { VH, VW } from "../function/sizeAdapter";
import HeaderBarIcon from "../icon/HeaderBarIcon";
import HeaderBar_logo from '../icon/HeaderBar_logo';
import log from '../function/log'
import { PocInspection, PocUserInfo } from '../../network/api';
import { Token } from 'aws-sdk';

const styles = (theme) => ({
    headerBar: {
        width: '100%',
        height: '100%',
        display: 'flex'
    },
    iconBlock: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        transition: 'all 0.1s',
        overflow: 'hidden',
        fontWeight: 'bold',
        fontSize: VW(16),
        color: '#FFF'
    },
    menuBlock: {
        height: '100%',
        // borderLeft: `1px solid ${theme.palette.themeBorder.main}`,
        borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        transition: 'all 0.1s',
    },
    leftSelect: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        height: '50%',
        display: 'flex',
    },
    rightSelect: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        right: '2%',
        height: '50%',
        display: 'flex',
        paddingLeft: VW(16),
        borderLeft: '1px solid #DDD'
    },
    projectBlock: {
        fontSize: VW(15),
        borderRight: '1px solid #DDD',
        paddingRight: VW(16),
        marginRight: VW(16)
    },
    selectIcon: {
        width: VH(40),
        height: VH(40),
        borderRadius: '50%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#D9D9D9',
        color: '#FFF',
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    menuButton: {
        cursor: 'pointer',
        width: VW(22),
        height: VH(22),
        borderRadius: '50%',
        margin: VW(17),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: '#DDD'
        }
    },
    profileButton: {
        position: 'absolute',
        right: '0',
        marginTop: VH(10),
        padding: VW(13),
        marginRight: VW(-15),
        width: VW(286),
        height: VH(47),
        fontSize: VW(14),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#FFF',
        color: 'black',
        boxShadow: '0px 0 0.85vh 0.12vw #DDD',
        borderRadius: "4px 4px 0 0"
    },
    logoutButton: {
        position: 'absolute',
        right: '0',
        marginTop: VH(57),
        // marginTop: VH(10),
        padding: VW(13),
        marginRight: VW(-15),
        width: VW(286),
        height: VH(47),
        fontSize: VW(14),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#FFF',
        color: 'black',
        boxShadow: '0px 0.12vw 0.85vh 0.12vw #DDD',
        borderRadius: "0 0 4px 4px"
    },
    authorityBlock: {
        fontSize: VW(15),
        display: 'flex',
        '& > div': {
            alignSelf: 'center',
            marginRight: VW(16)
        }
    },
});

function HeaderBar(props) {
    const {
        classes,
        changeMenuStatus,
        menuFlag,
        tenant,
        setTenant,
        store,
        setStore
    } = props

    const navigate = useNavigate()
    const { Option } = Select;
    const S3_BUCKET = process.env.REACT_APP_BUCKET;


    // username
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    }

    useEffect(() => {
        //getselectList()
        getUserInfo()
    }, [])

    // to login
    const goToLogin = () => {
        navigate('/')
    }

    // to personalData
    const toPersonalData = () => {
        navigate(routerNames.PersonalData);
        setOpen(false)
    }
    const [userObject, setUserObject] = useState(qs.parse(localStorage.getItem('userObject')))

    const getUserInfo = () => {
        PocInspection({token: localStorage.getItem('access_token')}).then(res => {
            if (res.data.active) {
                let userId = res.data.sub
                PocUserInfo({userid: userId}).then(res => {
                    localStorage.setItem('userObject', qs.stringify(res.data))
                    setUserObject(res.data);
                })
            }
        }).catch(err => {

        })
    }

    // get select list
    const getselectList = () => {
        ApiAdminAuthorityList().then(res => {
            let resData = res.data
            if (res.status === 200) {
                log(resData)
                if (resData.data[0].authority_id == 1) {
                    setTenant('controller')
                    localStorage.setItem('authority_id', 1)
                    let params = {
                        email: localStorage.getItem("username"),
                    }
                    if (params.email) {
                        ApiUserUserauthority(params).then(res => {
                            let resData = res.data
                            if (res.status === 200) {
                                localStorage.setItem('userObject', qs.stringify(resData))
                                setUserObject(qs.parse(localStorage.getItem('userObject')));
                            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {

                            }
                        }).catch(err => {

                        })
                    }
                } else {
                    resData.data.map(item => {
                        item.projects.map(child => {
                            if (child.project_id == null) {
                                child.project_id = -1
                                child.project_name = '全店舗'
                            }
                        })
                    })
                    setTenantOption(resData.data)
                    setTenant(resData.data[0].tenant_id)
                    setStoreOption(resData.data[0].projects)
                    setStore(resData.data[0].projects[0].project_id)
                    localStorage.setItem('tenant-id', resData.data[0].tenant_id)
                    localStorage.setItem('project-id', resData.data[0].projects[0].project_id)
                    localStorage.setItem('authority_id', resData.data[0].projects[0].authority_id)
                    let params = {
                        email: localStorage.getItem("username"),
                        tenant_id: resData.data[0].tenant_id,
                        project_id: resData.data[0].projects[0].project_id
                    }
                    if (params.email) {
                        ApiUserUserauthority(params).then(res => {
                            let resData = res.data
                            if (res.status === 200) {
                                localStorage.setItem('userObject', qs.stringify(resData))
                                setUserObject(qs.parse(localStorage.getItem('userObject')));
                            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {

                            }
                        }).catch(err => {

                        })
                    }
                }
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {

            }
        }).catch(err => {

        })
    }
    // テナント select 
    const [tenantOption, setTenantOption] = useState([])
    const changeTenant = (event) => {
        setTenant(event)
        localStorage.setItem('tenant-id', event)
        tenantOption.map(item => {
            if (item.tenant_id == event) {
                setStoreOption(item.projects)
                setStore(item.projects[0].project_id)
                localStorage.setItem('project-id', item.projects[0].project_id)
            }
        })
    }
    // 店舗 select 
    const [storeOption, setStoreOption] = useState([])
    const changeStore = (event) => {
        setStore(event)
        localStorage.setItem('project-id', event)
    }

    return (
        <div className={classes.headerBar}>
            <div className={classes.iconBlock} style={{ width: menuFlag ? '17%' : '0', paddingLeft: menuFlag ? VW(16) : '0', color: '#000000' }} >教育PDS実証実験用アプリ</div>
            <div className={classes.menuBlock} style={{ width: menuFlag ? '83%' : '100%' }}>
                <div className={classes.leftSelect}>
                    <div className={classes.menuButton} onClick={changeMenuStatus}><HeaderBarIcon /></div>
                </div>
                {tenantOption.length > 0 &&
                    <div style={{ marginLeft: VW(73), display: 'flex', height: VH(40) }}>
                        <div className={classes.authorityBlock}>
                            <div>テナント</div>
                            <Select
                                style={{ width: VW(200), height: '100%', alignItems: 'center' }}
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                                value={tenant}
                                onChange={changeTenant}
                            >
                                {tenantOption.map((item, index) => {
                                    return (
                                        <Option style={{ fontSize: VW(16) }} key={index} value={item.tenant_id}>{item.tenant_name}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className={classes.authorityBlock} style={{ marginLeft: VW(16) }}>
                            <div>店舗</div>
                            <Select
                                style={{ width: VW(200), height: '100%', alignItems: 'center' }}
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                                value={store}
                                onChange={changeStore}
                            >
                                {storeOption.map((item, index) => {
                                    return (
                                        <Option style={{ fontSize: VW(16) }} key={index} value={item.project_id}>{item.project_name}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                }
                <div className={classes.rightSelect}>
                    <div className={classes.selectIcon}>
                        {userObject &&
                            <img
                                src={userObject.head_sculpture}
                                width="100%"
                                height="100%"
                            />
                        }
                    </div >
                    <div>
                        <List>
                            <ListItemButton style={{ display: "flex", justifyContent: 'center', position: 'relative' }} onClick={handleClick}>
                                <span style={{ fontSize: VW(15), color: "#333" }}>{userObject?.surname?userObject?.surname:''  + ' ' + userObject?.name?userObject?.name:''}</span>
                                <div style={{ display: "flex", justifyContent: 'center', position: 'relative', marginLeft: VW(40) }}>
                                    {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                </div>
                            </ListItemButton>
                            <Collapse in={open} timeout="auto" unmountOnExit style={{ position: 'absolute', width: '100%', zIndex: '9999' }}>
                                <List component="div" disablePadding>
                                    {/* <Button
                                        // type="primary"
                                        className={classes.profileButton}
                                        onClick={toPersonalData}
                                    >プロフィール</Button> */}
                                    <Button
                                        // type="primary"
                                        className={classes.profileButton}
                                        onClick={goToLogin}
                                    >ログアウト</Button>
                                </List>
                            </Collapse>
                        </List>
                    </div>
                </div >
            </div >
        </div >
    )
}

HeaderBar.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(HeaderBar);