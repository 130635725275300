import React, { useEffect, useState, Fragment } from "react";
import {
    Button,
    TextField,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { routerNames } from '../wordsFile/wordsFile'
import { VW, VH } from "../componets/function/sizeAdapter"
import projectLogo from '../componets/picture/projectLogo.png'

const styles = (theme) => ({
    groundBlock: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        // borderBottom: '1px solid #EBE2E2',
        // borderRight: '1px solid #EBE2E2'
    },
    LoginView: {
        width: VW(680),
        backgroundColor: '#FFF',
        boxShadow: '0px 0.26vw 1.05vh 0.26vw #EAEFF3',
        textAlign: 'center',
        paddingTop: VH(40),
        position: 'absolute',
    },
    tittleIcon: {
        width: '100%',
        textAlign: 'center',
        fontSize: VW(24),
        fontWeight: 'bold'
    },
    annotateText: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(24),
        '& > div': {
            width: VW(400),
            textAlign: 'left',
            fontSize: VW(16),
        }
    },
    text: {
        width: '100%',
        display: "flex",
        justifyContent: 'center',
        fontSize: VW(16),
    },
    loginButton: {
        backgroundColor: theme.palette.primary.main,
        marginTop: VH(40),
        fontSize: VW(16),
        fontWeight: 'bold',
        marginBottom: VH(40)
    },
    input: {
        height: VH(48),
        fontSize: VW(16),
    },
    inputContent: {
        padding: VH(14),
        fontSize: VW(16),
        fontSize: VW(16)
    }
});

function ChangeOK(props) {
    const {
        classes,
    } = props



    // to Login
    const toLogin = () => {
        window.location.href = window.location.origin + '/'
    }


    return (
        <div className={classes.groundBlock}>
            <div className={classes.LoginView}>
                <img
                    style={{ height: VH(70), objectFit: "contain", marginBottom: VH(10) }}
                    src={projectLogo}
                />
                <div className={classes.tittleIcon}>パスワード再設定完了</div>
                <div className={classes.annotateText}>
                    <div>パスワード再設定が完了しました。<br />下記のボタンより再度ログインしてください。</div>
                </div>
                <Button
                    className={classes.loginButton}
                    variant="contained"
                    sx={{ width: VW(400), height: VH(48) }}
                    onClick={toLogin}
                >ログイン画面へ
                </Button>
            </div>
        </div >
    )
}

ChangeOK.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(ChangeOK);