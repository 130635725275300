
import request from './request.js';


export const ApiAdminUserProfile = (data) => request.get('/admin/user_profile', { data })
export const ApiAdminAuthorityList = (data) => request.get('/admin/authority_list', { data })


export const ApiAdminMenu = (data) => request.get('/admin/menu', { data })

export const ApiUserUserauthority = (data) => request.get('/user/userauthority', { data })
export const ApiUserUpdate = (data) => request.post('/user/update', { data })
export const ApiUserHeadsculpture = (data) => request.post('/user/headsculpture', { data })
export const ApiUserResources = (data) => request.get('/user/resources', { data })


export const ApiAdminProjectAdd = (data) => request.post('/admin/project/add', { data })
export const ApiAdminProjectList = (data) => request.get('/admin/project/list', { data })
export const ApiAdminProjectDetail = (data) => request.get('/admin/project/detail', { data })
export const ApiAdminProjectUpdate = (data) => request.post('/admin/project/update', { data })
export const ApiAdminProjectDelete = (data) => request.post('/admin/project/delete', { data })
export const ApiAdminProjectReinvite = (data) => request.post('/admin/project/reinvite', { data })

export const ApiTenantList = (data) => request.get('/admin/tenant/list', { data })
export const ApiTenantAdd = (data) => request.post('/admin/tenant/add', { data })
export const ApiTenantDelete = (data) => request.post('/admin/tenant/delete', { data })
export const ApiTenantUpdate = (data) => request.post('/admin/tenant/update', { data })
export const ApiTenantReinvite = (data) => request.post('/admin/tenant/reinvite', { data })
//export const ApiApplicationList = (data) => request.get('/application/list', { data })
//export const ApiApplicationListContract = (data) => request.get('application/list/contract', { data })

export const ApiCompanyMemberList = (data) => request.get('/admin/company_member/list', { data })
export const ApiCompanyMemberAuthority = (data) => request.get('/admin/company_member/authority', { data })
export const ApiCompanyMemberAdd = (data) => request.post('/admin/company_member/add', { data })
export const ApiCompanyMemberDetail = (data) => request.get('/admin/company_member/detail', { data })
export const ApiCompanyMemberUpdate = (data) => request.post('/admin/company_member/update', { data })
export const ApiCompanyMemberDelete = (data) => request.post('/admin/company_member/delete', { data })
export const ApiCompanyMemberReinvite = (data) => request.post('/admin/company_member/reinvite', { data })
export const ApiCompanyMemberExit = (data) => request.get('/admin/company_member/exit', { data })
export const ApiCompanyMemberActive = (data) => request.post('/admin/company_member/active', { data })

export const PocLogin = (data) => request.post('/login', { data, baseURL:process.env.REACT_APP_POC_HOST })
export const PocInspection = (data) => request.post('/inspection', { data, baseURL:process.env.REACT_APP_POC_HOST })
export const PocUserInfo = (data) => request.post('/get-user-detail', { data, baseURL:process.env.REACT_APP_POC_HOST })