import CreateIcon from '@mui/icons-material/Create';
import { withStyles } from "@mui/styles";
import { Button } from 'antd';
import qs from 'qs';
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import log from "../../componets/function/log";
import { VH, VW } from "../../componets/function/sizeAdapter";
import PersonalData_icon from "../../componets/icon/PersonalData_icon";
import PersonalData_icon_large from "../../componets/icon/PersonalData_icon_large ";
import { ApiUserUserauthority } from '../../network/api';
import { routerNames } from '../../wordsFile/wordsFile';

const styles = (theme) => ({
    ground: {
        width: '100%',
        height: '100%',
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24)
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8)
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    updateDeleteBlock: {
        position: 'absolute',
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& > Button': {
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    headSculpture: {
        height: VH(232),
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    contentBody: {
        display: 'flex',
        borderTop: '1px solid #DDD',
        '& > div': {
            height: VH(74),
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16)
        },
        '& > div:nth-child(1)': {
            width: VW(240),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(2)': {
            width: VW(752),
        }
    },
    bottomButton: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VW(40),
        '& > Button': {
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
    }
});

function PersonalData(props) {
    const {
        classes,
        setUserObject
    } = props

    useEffect(() => {
        getUserData()
    }, [])


    const navigate = useNavigate()


    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')

    // to detail
    const toDetail = () => {
        navigate(routerNames.PersonalDataDetail, { state: userData })
    }

    const [userData, setUserData] = useState({})

    // data api
    const getUserData = () => {
        if (localStorage.getItem("authority_id") == 1) {
            let params = {
                email: localStorage.getItem("username"),
            }
            ApiUserUserauthority(params).then(userRes => {
                if (userRes.status === 200) {
                    log(userRes.data)
                    setUserData(userRes.data)
                    setUserObject(userRes.data)
                } else {
                    setErrorContent(userRes.data.detail)
                    setRrrorOpen(true)
                }
            }).catch(err => {

            })
        } else {
            let params = {
                email: localStorage.getItem("username"),
                tenant_id: localStorage.getItem("tenant-id"),
                project_id: localStorage.getItem("project-id")
            }
            ApiUserUserauthority(params).then(userRes => {
                if (userRes.status === 200) {
                    log(userRes.data)
                    setUserData(userRes.data)
                    setUserObject(userRes.data)
                } else {
                    setErrorContent(userRes.data.detail)
                    setRrrorOpen(true)
                }
            }).catch(err => {

            })
        }
    }


    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}>プロフィール</div>
            <div className={classes.tableTitle}>
                <PersonalData_icon />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>プロフィール</div>
                <div className={classes.updateDeleteBlock}>
                    <Button style={{ padding: 0, marginRight: VW(24) }} type="text" icon={<CreateIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={toDetail}>&nbsp;編集</Button>
                </div>
            </div>
            <div className={classes.headSculpture}>
                {userData.head_sculpture ?
                    <div style={{ width: '200px', height: '200px', borderRadius: '50%', marginBottom: VH(24), overflow: 'hidden' }}>
                        <img
                            src={userData.head_sculpture}
                            width="100%"
                            height="100%"

                        />
                    </div>
                    :
                    <PersonalData_icon_large />
                }
            </div>
            <div className={classes.contentBody}>
                <div>氏名</div>
                <div>{userData.surname + userData.name}</div>
            </div>
            <div className={classes.contentBody}>
                <div>メールアドレス</div>
                <div>{userData.email}</div>
            </div>
            <div className={classes.contentBody}>
                <div>所属</div>
                <div>{userData.affiliation}</div>
            </div>
            <div className={classes.contentBody}>
                <div>権限</div>
                <div>{userData.authority_name}</div>
            </div>
            <div className={classes.contentBody} style={{ borderBottom: '1px solid #DDD' }}>
                <div>パスワード</div>
                <div>●●●●●●●●</div>
            </div>
            <div className={classes.bottomButton}>
                <Button onClick={() => { window.history.back() }}>戻る</Button>
            </div>
            <div style={{ height: VH(40) }}></div>
        </div>
    )
}

PersonalData.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PersonalData);