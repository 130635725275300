import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input } from 'antd';
import HeaderBarIcon from "../icon/HeaderBarIcon";
import CorrectIcon from "../icon/CorrectIcon";
import WrongIcon from "../icon/WrongIcon";
import log from "../function/log";
import { ApiTenantDelete } from '../../network/api'
import Common_errorAlert from "../common/Common_errorAlert";
import { VW, VH } from "../function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(26)
    },
    headerBlock: {
        display: 'flex',
        width: '100%',
        height: VH(56),
        marginTop: VH(16),
        backgroundColor: '#F8F8F8',
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: '#DDDDDD',
        '& > div': {
            height: '100%',
            fontWeight: 'bold',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.26vw'
        },
        '& > div:nth-child(1)': {
            width: '15%'
        },
        '& > div:nth-child(2)': {
            width: '20%'
        },
        '& > div:nth-child(3)': {
            width: '7%'
        },
        '& > div:nth-child(4)': {
            width: '10%'
        },
        '& > div:nth-child(5)': {
            width: '18%'
        },
        '& > div:nth-child(6)': {
            width: '30%'
        }
    },
    scrollBlock: {
        width: '100%',
        maxHeight: VH(224),
        overflowY: 'scroll',
        overflowY: 'overlay'
    },
    bodyBlock: {
        display: 'flex',
        width: '100%',
        height: VH(56),
        borderBottom: '1px solid #DDD',
        '& > div': {
            height: '100%',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.26vw',
            overflow: 'hidden'
        },
        '& > div:nth-child(1)': {
            width: '15%'
        },
        '& > div:nth-child(2)': {
            width: '20%'
        },
        '& > div:nth-child(3)': {
            width: '7%'
        },
        '& > div:nth-child(4)': {
            width: '10%'
        },
        '& > div:nth-child(5)': {
            width: '18%'
        },
        '& > div:nth-child(6)': {
            width: '30%'
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: '#E60024',
            marginLeft: VW(16)
        }
    }
});

function Tenant_delete(props) {
    const {
        classes,
        open,
        onClose,
        deleteData,
        getList,
        setDeleteData,
        setIndeterminate,
        setCheckAll,
        historyFlag
    } = props

    useEffect(() => {
        if (open) {
            log(deleteData)
        } else {
            setTitleFlag(false)
            setRrrorOpen(false)
        }
    }, [open])


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // delete api
    const toDelete = () => {
        if (!titleFlag) {
            setTitleFlag(true)
        } else {
            let deleteList = []
            deleteData.map(item => {
                deleteList.push({ tenant_id: item.tenant_id })
            })
            ApiTenantDelete(deleteList).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    if (historyFlag) {
                        onClose()
                        window.history.back()
                        localStorage.setItem('deleteFlag', true)
                    } else {
                        getList('delete')
                        onClose()
                        setDeleteData([])
                        setIndeterminate(false)
                        setCheckAll(false)
                    }
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }

    // title text flag
    const [titleFlag, setTitleFlag] = useState(false)


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div className={classes.tableTitle}>
                <HeaderBarIcon />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>テナントの削除</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
            />
            {titleFlag ? <div style={{ color: '#E60024', fontSize: VW(16) }}>以下のテナント情報を削除します。<br />削除されたデータは復元できませんが、本当に削除しますか？</div> : <div style={{ fontSize: VW(16) }}>以下のテナントを削除します。<br />関連情報（メンバー情報など）も合わせて削除されます。</div>}
            <div className={classes.headerBlock}>
                <div>テナントCD</div>
                <div>テナント名</div>
                <div>招待</div>
                <div>契約</div>
                <div>管理者</div>
                <div>メールアドレス</div>
            </div>
            <div className={classes.scrollBlock}>
                {deleteData && deleteData.map((item, index) => {
                    return (
                        <div className={classes.bodyBlock} key={index}>
                            <div>{item.tenant_id}</div>
                            <div>
                                <p>{item.tenant_name}</p>
                            </div>
                            <div>
                                {item.login_status === 'correct' ? <CorrectIcon /> : item.login_status === 'wrong' ? <WrongIcon /> : ''}
                            </div>
                            <div>
                                <p>{item.tenant_contract_status}</p>
                            </div>
                            <div>
                                <p>{item.representative}</p>
                            </div>
                            <div>
                                <p>{item.email}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={classes.buttonGroup}>
                <Button onClick={onClose}>キャンセル</Button>
                <Button danger onClick={toDelete}>削除</Button>
            </div>
        </Modal>
    )
}

Tenant_delete.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Tenant_delete);