import axios from "axios";
import dealReturn from "./dealReturn";
import devConfig from "./config";
import ReactDOM from 'react-dom/client';
import System_error from "../componets/dialog/System_error";
import System_error_431 from "../componets/dialog/System_error_431";
import { showLoading, hideLoading } from "../componets/function/loading";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";



// 显示dialog
function showDialog() {
  var dom = document.createElement('div')
  dom.setAttribute('id', 'errorDialog')
  document.body.appendChild(dom)
  ReactDOM.createRoot(dom).render(
    <System_error
      goLogin={goLogin}
    />
  )
}
// 隐藏dialog
function hideDialog() {
  document.body.removeChild(document.getElementById('errorDialog'))
}
function goLogin() {
  hideDialog()
  window.location.href = '/login'
}

// 显示dialog ---  431
function showDialog_431() {
  var dom = document.createElement('div')
  dom.setAttribute('id', 'errorDialog_431')
  document.body.appendChild(dom)
  ReactDOM.createRoot(dom).render(
    <System_error_431
      goLogin={goLogin_431}
    />
  )
}
// 隐藏dialog
function hideDialog_431() {
  document.body.removeChild(document.getElementById('errorDialog_431'))
}
function goLogin_431() {
  hideDialog_431()
  window.location.href = '/login'
}

axios.interceptors.request.use(
  async (config) => {
    config.headers["authorization"] = localStorage.getItem('access_token') ? "Bearer" + ' ' + localStorage.getItem('access_token') : ''
    if (localStorage.getItem('tenant-id') != null && localStorage.getItem('tenant-id') != undefined) {
      config.headers["tenant-id"] = localStorage.getItem('tenant-id')
    }
    if (localStorage.getItem('project-id') != null && localStorage.getItem('project-id') != undefined && localStorage.getItem('project-id') != -1) {
      config.headers["project-id"] = localStorage.getItem('project-id')
    }
    showLoading()
    return config;
  },
  (error) => {
    hideLoading()
    return Promise.reject(error)
  }
);

axios.interceptors.response.use(
  (response) => {
    hideLoading()
    if (!response.status) {
      window.location.reload();
    }

    if (response.status === 200) {
      return response;
    }

    return dealReturn(response);
  },
  (error) => {
    hideLoading()
    if (error.code == 'ERR_NETWORK') {
      showDialog()
    }
    if (error.response.status === 431) {
      showDialog_431()
    }
    return dealReturn(error.response);
  }
);

const createBaseUrl = (() => {
  const { host, baseUrl } = devConfig;
  return host + baseUrl;
})();

const request = (method, url, param) => {
  let temp = {
    baseURL: createBaseUrl,
    timeout: 30 * 1000,
    withCredentials: false,
    method,
    url,
  };
  if (param) {
    temp = Object.assign(temp, param);
  }
  return axios.request(temp);
};

export default {
  get: (url, data) => {
    let urlParam = "";
    if (
      !data ||
      JSON.stringify(data) === "{}" ||
      !data.data ||
      JSON.stringify(data.data) === "{}"
    ) {
    } else {
      for (let key in data.data) {
        if (key) {
          if (!urlParam) {
            urlParam = "?" + key + "=" + data.data[key];
          } else {
            urlParam = urlParam + "&" + key + "=" + data.data[key];
          }
        }
      }
      url = url + urlParam;
    }
    return request("get", url, data);
  },
  post: (url, data) => request("post", url, data),
  put: (url, data) => request("put", url, data),
  delete: (url, data) => request("delete", url, data),
  patch: (url, data) => request("patch", url, data),
};
