import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoRefreshToken } from 'amazon-cognito-identity-js'


export const Result = {
  ok: (code, data) => {
    return { status: "ok", code, data }
  },
  err: (code, data) => {
    return { status: "err", code, data }
  }
}

const userPoolId = process.env.REACT_APP_USERPOOLID
const clientId = process.env.REACT_APP_CLIENTID

const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`,
}

const userPool = new CognitoUserPool(poolData)

let currentUser = userPool.getCurrentUser()

export function getCurrentUser() {
  return currentUser
}

function getCognitoUser(username) {
  const userData = {
    Username: username,
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)

  return cognitoUser
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser()
  }

  return new Promise(function (resolve, reject) {
    currentUser.getSession(function (err, session) {
      if (err) {
        reject(err)
      } else {
        resolve(session)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function signUpUserWithEmail(username, email, password) {
  return new Promise(function (resolve, reject) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
    ]

    userPool.signUp(username, password, attributeList, [], function (err, res) {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function verifyCode(username, code) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username)

    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function signInWithEmail(username, password) {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password
    }
    const authenticationDetails = new AuthenticationDetails(authenticationData)
    currentUser = getCognitoUser(username)
    currentUser.authenticateUser(authenticationDetails, {
      onSuccess: function (res) {

        resolve(Result.ok("success", res))
      },
      onFailure: function (err) {
        reject(err)
      },
      newPasswordRequired: function (newPasswordRequired) {
        resolve(Result.ok("newPasswordRequired", newPasswordRequired))
      }
    })
  }).catch((err) => {
    throw err
  })
}

export function signOut() {
  if (currentUser) {
    currentUser.signOut()
  }
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    currentUser.getUserAttributes(function (err, attributes) {
      if (err) {
        reject(err)
      } else {
        resolve(attributes)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function setAttribute(attribute) {
  return new Promise(function (resolve, reject) {
    const attributeList = []
    const res = new CognitoUserAttribute(attribute)
    attributeList.push(res)

    currentUser.updateAttributes(attributeList, (err, res) => {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function forgotPassword(username) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username)

    if (!cognitoUser) {
      reject(Result.err(`could not find ${username}`))
      return
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res) {
        resolve(Result.ok("success", res))
      },
      onFailure: function (err) {
        reject(err)
      },
    })
  }).catch((err) => {
    throw err
  })
}

export async function confirmPassword(username, code, password) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username)

    if (!cognitoUser) {
      reject(Result.err(`could not find ${username}`))
      return
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function (res) {
        resolve(Result.ok("success", res))
      },
      onFailure: function (err) {
        reject(err)
      },
    })
  })
}

export async function changePassword(oldPassword, newPassword) {
  return new Promise(function (resolve, reject) {
    currentUser.changePassword(oldPassword, newPassword, function (err, res) {
      if (err) {
        reject(err)
      } else {
        resolve(Result.ok("success", res))
      }
    })
  })
}

export async function completeNewPasswordChallenge(password) {
  return new Promise(function (resolve, reject) {
    currentUser.completeNewPasswordChallenge(password, {}, {
      onSuccess: function (res) {
        resolve(Result.ok("success", res))
      },
      onFailure: function (err) {
        reject(err)
      }
    })
  })
}

export async function sendCustomChallengeAnswer(code) {
  return new Promise(function (resolve, reject) {
    currentUser.sendCustomChallengeAnswer(code, {
      onSuccess: function (res) {
        resolve(Result.ok("success", res))
      },
      onFailure: function (err) {
        reject(err)
      },
      customChallenge: function (challengeParameters) {
        reject(err)
      },
    })
  })
}

export async function refreshToken() {
  return new Promise(function (resolve, reject) {
    currentUser = getCognitoUser(localStorage.getItem("username"))
    currentUser.refreshSession(new CognitoRefreshToken({ RefreshToken: localStorage.getItem("refresh_token") }), function (err, session) {
      if (err) {
        reject(err)
      } else {
        resolve(Result.ok("success", session))
      }
    })
  })
}
