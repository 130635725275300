import { withStyles } from "@mui/styles";
import { DatePicker, Input, Select } from 'antd';
import locale from "antd/es/date-picker/locale/ja_JP";
import React from "react";
import { VH, VW } from "../function/sizeAdapter";
import AddIcon from "../icon/AddIcon";
import Red_star from "../common/Red_star";

const { Option } = Select;
const styles = (theme) => ({
    updateInput: {
        width: '100%',
        '& > div:not(:last-child)': {
            width: '100%',
            height: VH(74),
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div:not(:last-child) > div': {
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16)
        },
        '& > div:not(:last-child) > div > Input': {
            width: '100%',
            height: '80%',
            fontSize: VW(16)
        },
        '& > div:not(:last-child) > div:nth-child(1)': {
            width: VW(160),
            height: '100%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(2) > div:nth-child(2)': {
            width: '82%',
            height: '100%'
        },
        '& > div:last-child': {
            width: '100%',
            height: VH(50),
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: VW(16),
            Left: VW(19),
            '& > div': {
                cursor: 'pointer',
                color: theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
            }
        }
    },
    InputAdministrator: {
        width: '100%',
        height: VH(74),
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16)
        },
        '& > div:nth-child(1)': {
            width: '16%',
            height: '100%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
            padding: '0 0.78vw',
        },
        '& > div:nth-child(2)': {
            width: '13%',
            padding: '0 0.39vw 0 0.78vw',
        },
        '& > div:nth-child(3)': {
            width: '13%',
            padding: '0 0.78vw 0 0.39vw',
        },
        '& > div:nth-child(4)': {
            width: '16%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
            padding: '0 0.78vw',
        },
        '& > div:nth-child(5)': {
            width: '42%',
            padding: '0 0.78vw',
        },
        '& > div > Input': {
            width: '100%',
            height: VH(50),
            fontSize: VW(16)
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    updateInputItem: {
        width: '100%',
        height: VH(74),
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        '& > div': {
            padding: '0 0.78vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16)
        },
        '& > div:nth-child(1)': {
            width: '16%',
            height: '100%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(2)': {
            width: '84%',
            height: '100%'
        },
        '& > div > Input': {
            width: '100%',
            height: VH(50),
            fontSize: VW(16)
        },
        '& > div > div:nth-child(1)': {
            width: "3%",
        },
        '& > div > div:nth-child(2)': {
            width: "22%",
            minHeight: VH(50)
        },
        '& > div > div:nth-child(3)': {
            fontSize: VW(16),
            color: '#AAA',
            marginLeft: VW(16)
        },
        '& > div > div > Input': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px",
            minHeight: VH(50)
        },
    },
    updateInputComplex: {
        width: '100%',
        '& > div:not(:last-child)': {
            width: '100%',
            height: VH(74),
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div:not(:last-child) > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16)
        },
        '& > div:not(:last-child) > div > Input': {
            width: '100%',
            height: VH(50),
            fontSize: VW(16)
        },
        '& > div:not(:last-child) > div:nth-child(1)': {
            width: '16%',
            height: '100%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:last-child': {
            width: '100%',
            height: VH(50),
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: '1vw',
            '& > div': {
                cursor: 'pointer',
                color: theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '0.78vw',
            }
        },
    },
    administratorBlock: {
        '& > div': {
            paddingLeft: VW(16)
        },
        '& > div:nth-child(2)': {
            width: '26%',
        },
        '& > div:nth-child(3)': {
            width: '16%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
            overflow: 'hidden'
        },
        '& > div:nth-child(4)': {
            width: '38%',
            overflow: 'hidden'
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
});

function ProjectManagement_input(props) {
    const {
        classes,
        detailData,
        changeDetailData,
        administratorList,
        updateAdministratorList,
        addAdministratorListItem,
    } = props

    return (
        <div style={{ marginTop: VH(12) }}>
            <div className={classes.updateInputItem}>
                <div>店舗CD</div>
                <div>{detailData.project_id}</div>
            </div>
            <div className={classes.updateInputItem}>
                <div>店舗名<Red_star /> </div>
                <div>
                    <Input
                        value={detailData.project_name}
                        onChange={(event) => changeDetailData(event.target.value, 'project_name')}
                        maxLength={60}
                        placeholder='店舗名を入力してください'
                    />
                </div>
            </div>
            <div className={classes.updateInputComplex}>
                {detailData?.project_manager.length > 0 && detailData?.project_manager.map((item, index) => {
                    return (
                        <div className={classes.administratorBlock} key={index}>
                            <div>管理者</div>
                            <div>
                                <p>{item.first_name + item.last_name}</p>
                            </div>
                            <div>メールアドレス</div>
                            <div>
                                <p>{item.email}</p>
                            </div>
                        </div>
                    )
                })}
                {administratorList.map((item, index) => {
                    return (
                        <div className={classes.InputAdministrator} key={index}>
                            <div>管理者</div>
                            <div>
                                <Input
                                    placeholder="姓"
                                    value={item.first_name}
                                    onChange={(event) => updateAdministratorList(event.target.value, index, 'first_name')}
                                    maxLength={30}
                                />
                            </div>
                            <div>
                                <Input
                                    placeholder="名"
                                    value={item.last_name}
                                    onChange={(event) => updateAdministratorList(event.target.value, index, 'last_name')}
                                    maxLength={30}
                                />
                            </div>
                            <div>メールアドレス</div>
                            <div>
                                <Input
                                    value={item.email}
                                    onChange={(event) => updateAdministratorList(event.target.value, index, 'email')}
                                    maxLength={200}
                                />
                            </div>
                        </div>
                    )
                })}
                <div>
                    <div onClick={addAdministratorListItem} style={{ fontSize: VW(16) }}>
                        <AddIcon />&ensp;管理者を追加する
                    </div>
                </div>
            </div>
            <div className={classes.updateInputItem}>
                <div>郵便番号</div>
                <div>
                    <div>〒</div>
                    <div>
                        <Input
                            value={detailData.postal_code}
                            onChange={(event) => changeDetailData(event.target.value, 'postal_code')}
                            placeholder="郵便番号"
                            maxLength={8}
                        />
                    </div>
                    <div>※半角７桁ハイフンあり</div>
                </div>
            </div>
            <div className={classes.updateInputItem}>
                <div>住所</div>
                <div>
                    <Input
                        value={detailData.project_address}
                        onChange={(event) => changeDetailData(event.target.value, 'project_address')}
                        placeholder='住所を入力してください'
                        maxLength={50}
                    />
                </div>
            </div>
            <div className={classes.updateInputItem}>
                <div>アクセス情報</div>
                <div>
                    <Input
                        value={detailData.access_information}
                        onChange={(event) => changeDetailData(event.target.value, 'access_information')}
                        placeholder='例：地下鉄〇〇駅△△出口から徒歩5分／△△バス停の目の前／□□交差点を右折してすぐ'
                        maxLength={50}
                    />
                </div>
            </div>
            <div className={classes.updateInputItem}>
                <div>電話番号</div>
                <div>
                    <Input
                        value={detailData.phone}
                        onChange={(event) => changeDetailData(event.target.value, 'phone')}
                        placeholder='000-0000-0000'
                        maxLength={15}
                    />
                </div>
            </div>
            <div className={classes.updateInputItem}>
                <div>営業時間</div>
                <div>
                    <Input
                        value={detailData.business_hours}
                        onChange={(event) => changeDetailData(event.target.value, 'business_hours')}
                        placeholder='例：10:00-16:00（毎週火曜日は定休日）'
                        maxLength={50}
                    />
                </div>
            </div>
            <div className={classes.updateInputItem}>
                <div>取り扱いジャンル</div>
                <div>
                    <Input
                        value={detailData.handling_genre}
                        onChange={(event) => changeDetailData(event.target.value, 'handling_genre')}
                        placeholder='例：スノーボード/サーフィンなど、ボードのことならお任せください'
                        maxLength={50}
                    />
                </div>
            </div>
            <div className={classes.updateInputItem}>
                <div>取り扱いブランド</div>
                <div>
                    <Input
                        value={detailData.handling_brand}
                        onChange={(event) => changeDetailData(event.target.value, 'handling_brand')}
                        placeholder='例：人気の〇〇など多数取り扱っております'
                        maxLength={50}
                    />
                </div>
            </div>
            <div className={classes.updateInputItem} style={{ borderBottom: '1px solid #EBE2E2' }}>
                <div>登録日</div>
                <div>{detailData.create_date}</div>
            </div>
        </div>
    )
}

ProjectManagement_input.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(ProjectManagement_input);