import { ArrowForwardIos } from "@mui/icons-material";
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Button } from 'antd';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TenantManagementDetail_input from "../../componets/HtmlBody/TenantManagementDetail_input";
import TenantManagementDetail_item from "../../componets/HtmlBody/TenantManagementDetail_item";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import Common_successAlert from "../../componets/common/Common_successAlert";
import Tenant_delete from "../../componets/dialog/Tenant_delete";
import log from "../../componets/function/log";
import { VH, VW } from "../../componets/function/sizeAdapter";
import dic from "../../componets/function/utc";
import HeaderBarIcon from "../../componets/icon/HeaderBarIcon";
import { ApiTenantUpdate } from '../../network/api';
import { errorMessage, routerNames } from '../../wordsFile/wordsFile';

dayjs.extend(utc)
const styles = (theme) => ({
    ground: {
        width: '100%',
        paddingRight: VW(24),
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24),
            paddingRight: VW(24)
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8),
        display: 'flex',
        alignItems: 'center',
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    updateDeleteBlock: {
        position: 'absolute',
        right: 0,
        display: 'flex',
        alignItems: 'center',
        marginTop: VH(16),
        justifyContent: 'flex-end',
        '& > Button': {
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& > Button:nth-child(2)': {
            color: '#E60024'
        }
    },
    bottomButton: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VW(40),
        marginBottom: VW(40),
        '& > Button': {
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function TenantManagementDetail(props) {
    const {
        classes,
        setSelectFlag
    } = props


    useEffect(() => {
        setSelectFlag(routerNames.TenantManagement)
        //getAppList()
        log(state)
    }, [setSelectFlag])

    const location = useLocation();
    const { state } = location;

    var authority_id = localStorage.getItem("authority_id")

    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')
    // add error email
    const [errorEmailOpen, setErrorEmailOpen] = useState(false)
    const [errorEmailContent, setErrorEmailContent] = useState('')



    const [updateFlag, setUpdateFlag] = useState(false)

    // detailed list data
    const [falseData, setFalseData] = useState(state)

    // delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteOpen(true)
    }

    // TenantManagementDetail_input
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  テナント名
    const [tenantName, setTenantName] = useState(falseData.tenant_name)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  契約開始日
    // const [startDay,setStartDay] = useState(falseData.tenant_contract_date_start ? dayjs(falseData.tenant_contract_date_start) : '')
    // const [startValue, setStartValue] = useState(falseData.tenant_contract_date_start);
    // const changeStartDay = (value, dateString) => {
    //     setStartDay(value)
    //     setStartValue(dateString);
    //     log(dateString)
    // };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  契約終了日
    const [endDay, setEndDay] = useState(falseData.tenant_contract_date_end ? dayjs(falseData.tenant_contract_date_end) : '')
    const [endValue, setEndValue] = useState(falseData.tenant_contract_date_end);
    const changeEndDay = (value, dateString) => {
        setEndDay(value)
        setEndValue(dateString);
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  管理者 list
    const [administratorList, setAdministratorList] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  管理者追加
    const addAdministratorListItem = () => {
        administratorList.push({ surname: '', name: '', email: '' })
        setAdministratorList([...administratorList])
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  list
    const updateAdministratorList = (item, index, flag) => {
        administratorList[index][flag] = item
        setAdministratorList([...administratorList])
        log(administratorList)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  アプリケーション
    // const [appList, setAppList] = useState([])
    // const [selectedApp, setSelectedApp] = useState([]);
    // // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  アプリケーション情報取得
    // const getAppList = () => {
    //     ApiApplicationList().then(res => {
    //         let resData = res.data
    //         if (res.status === 200) {
    //             setAppList(resData.data)
    //         }
    //         else if (res.status >= 300 && res.status < 500 && res.status != 401) {
    //             log(res)
    //             setRrrorOpen(true)
    //             setErrorContent(res.data.detail.toString())
    //         }
    //     }).catch(err => {

    //     })
    // }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  アプリケーション情報取得
    // const handleCheckboxChange = (appId) => {
    //     const newSelectedApp = [...selectedApp];
    //     const index = newSelectedApp.indexOf(appId);

    //     if (!appId) {
    //         newSelectedApp.length = 0
    //         if (index < 0) {
    //             appList.map((app) => {
    //                 newSelectedApp.push(app.application_id)
    //             })
    //         }
    //     }
    //     if (index < 0) {
    //         newSelectedApp.push(appId);
    //     }
    //     else {
    //         const indexZero = newSelectedApp.indexOf(0);
    //         if (!(indexZero < 0)) {
    //             newSelectedApp.splice(indexZero, 1);
    //         }
    //         newSelectedApp.splice(index, 1);
    //     }

    //     setSelectedApp(newSelectedApp)
    // };



    // update api
    const toUpdate = () => {
        document.getElementById('father_Block').scrollTop = 0;
        let InformationFlag = false
        administratorList.map(item => {
            if ((item.surname === '' && (item.name != '' || item.email != '')) || (item.name === '' && (item.surname != '' || item.email != '')) || (item.email === '' && (item.name != '' || item.surname != ''))) {
                InformationFlag = true
            }
        })
        if (tenantName === '') {
            setErrorContent(errorMessage.tenantNameNull)
            setRrrorOpen(true)
            setSuccessOpen(false)
        }
        // else if((startValue === '' && endValue != '') || dayjs(startValue) >= dayjs(endValue)){
        //     message.error(errorMessage.startEndDayNull)
        // }
        else if (InformationFlag) {
            setErrorContent(errorMessage.administratorNull)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else {
            let userList = []
            administratorList.map(item => {
                if (item.surname != '') {
                    userList.push(item)
                }
            })
            // let indexZero = selectedApp.indexOf(0);
            // if (!(indexZero < 0)) {
            //     selectedApp.splice(indexZero, 1);
            // }
            let params = {
                tenant_id: state.tenant_id,
                tenant_name: tenantName,
                user_masters: userList,
                // tenant_contract_date_start : startValue === '' ? null : dayjs.utc(startValue).format("YYYY-MM-DD HH:mm:ss"),
                tenant_contract_date_end: endValue === '' ? '' : endValue,
                // apps: selectedApp
            }
            ApiTenantUpdate(params).then(res => {
                if (res.status === 200) {
                    log(res.data)
                    let item = res.data.data[0]
                    item.login_status = item.login_status === '3' ? 'correct' : item.login_status === '2' ? 'wrong' : ''
                    item.representative = item.user_master.length > 0 ? item.user_master[0].surname + item.user_master[0].name + '（他' + item.user_master.length + '名）' : ''
                    item.email = item.user_master.length > 0 ? item.user_master[0].email : ''
                    item.create_date = dic.UTCDateToLocalDate(item.create_date)
                    item.tenant_contract_date_start = item.tenant_contract_date_start ? dic.UTCDateToLocalDate(item.tenant_contract_date_start) : ''
                    item.tenant_contract_date_end = item.tenant_contract_date_end ? dic.UTCDateToLocalDate(item.tenant_contract_date_end) : ''
                    setFalseData(item)
                    if (res.data.error_email.length > 0) {
                        setSuccessOpen(false)
                        let errConcent = ''
                        res.data.error_email.map(errorItem => {
                            errConcent = errConcent + errorItem.email + '\n'
                        })
                        setErrorEmailContent(errConcent)
                        setErrorEmailOpen(true)
                    } else {
                        setErrorEmailOpen(false)
                        setSuccessOpen(true)
                        setSuccessContent('更新が完了しました。')
                    }
                    setUpdateFlag(false)
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }

    }

    useEffect(() => {
        if (!updateFlag) {
            setTenantName(falseData.tenant_name)
            // setStartDay(falseData.tenant_contract_date_start ? dayjs(falseData.tenant_contract_date_start) : '')
            // setStartValue(falseData.tenant_contract_date_start)
            setEndDay(falseData.tenant_contract_date_end ? dayjs(falseData.tenant_contract_date_end) : '')
            setEndValue(falseData.tenant_contract_date_end)
            setAdministratorList([])
            // setSelectedApp(falseData.app)            
        } else {
            setSuccessOpen(false)
            setErrorEmailOpen(false)
        }
    }, [updateFlag])


    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}><span style={{ cursor: 'pointer' }} onClick={() => { window.history.back() }}>テナント一覧</span><ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>テナント情報</div>
            <div className={classes.tableTitle}>
                <HeaderBarIcon />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>{updateFlag ? 'テナント情報の編集' : 'テナント情報'}</div>
                <div className={classes.updateDeleteBlock}>
                    {!updateFlag &&
                        <>
                            <Button style={{ padding: 0, marginRight: VW(8) }} type="text" icon={<CreateIcon style={{ width: VW(24), height: VH(24), marginRight: VW(0) }} />} onClick={() => { setUpdateFlag(true) }}>&nbsp;編集</Button>
                            <Button style={{ padding: 0 }} type="text" danger icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(0) }} />} onClick={openDeleteDialog}>&nbsp;削除</Button>
                        </>
                    }
                </div>
            </div>
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
                marginTop={VH(12)}
            />
            <Common_errorAlert
                open={errorEmailOpen}
                setRrrorOpen={setErrorEmailOpen}
                errorContent={errorEmailContent}
                border
                marginTop={VH(12)}
            />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
            />
            {
                !updateFlag ?
                    <TenantManagementDetail_item
                        falseData={falseData}
                        setRrrorOpen={setRrrorOpen}
                        setErrorContent={setErrorContent}
                        setSuccessOpen={setSuccessOpen}
                        setSuccessContent={setSuccessContent}
                    // appList={appList}
                    // selectedApp={selectedApp}
                    />
                    :
                    <TenantManagementDetail_input
                        falseData={falseData}
                        tenantName={tenantName}
                        setTenantName={setTenantName}
                        // startDay = { startDay }
                        // changeStartDay = { changeStartDay }
                        endDay={endDay}
                        changeEndDay={changeEndDay}
                        administratorList={administratorList}
                        updateAdministratorList={updateAdministratorList}
                        addAdministratorListItem={addAdministratorListItem}
                    // selectedApp={selectedApp}
                    // appList={appList}
                    // handleCheckboxChange={handleCheckboxChange}
                    />
            }
            <div className={classes.bottomButton}>
                {!updateFlag ?
                    <Button onClick={() => { window.history.back() }}>テナント一覧へ戻る</Button>
                    :
                    <>
                        <Button onClick={() => { setUpdateFlag(false) }}>キャンセル</Button>
                        <Button onClick={toUpdate}>更新</Button>
                    </>
                }
            </div>
            <Tenant_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={[falseData]}
                historyFlag={true}
            />
        </div >
    )
}

TenantManagementDetail.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(TenantManagementDetail);