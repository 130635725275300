import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Button,
    IconButton,
    TextField
} from "@mui/material";
import { withStyles } from "@mui/styles";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Common_errorAlert from "../componets/common/Common_errorAlert";
import log from "../componets/function/log";
import { VH, VW } from "../componets/function/sizeAdapter";
import projectLogo from '../componets/picture/projectLogo.png';
import { errorMessage, formatCheck, routerNames } from '../wordsFile/wordsFile';
import { signInWithEmail } from "../network/cognito";
import { showLoading, hideLoading } from "../componets/function/loading";
import { PocLogin, PocInspection, PocUserInfo } from "../network/api";


const styles = (theme) => ({
    groundBlock: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        // borderBottom: '1px solid #EBE2E2',
        // borderRight: '1px solid #EBE2E2',
    },
    LoginView: {
        width: VW(680),
        backgroundColor: '#FFF',
        boxShadow: '0px 0.26vw 1.05vh 0.26vw #EAEFF3',
        textAlign: 'center',
        paddingTop: VH(40),
        position: 'absolute',
    },
    tittleIcon: {
        width: '100%',
        textAlign: 'center',
        fontSize: VW(24),
        fontWeight: 'bold'
    },
    label: {
        width: '100%',
        display: "flex",
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(24)
    },
    text: {
        width: '100%',
        display: "flex",
        justifyContent: 'center',
        fontSize: VW(16)
    },
    loginButton: {
        backgroundColor: theme.palette.primary.main,
        marginTop: VH(40),
        fontSize: VW(16),
        fontWeight: 'bold'
    },
    input: {
        height: VH(48),
        fontSize: VW(16),
    },
    inputContent: {
        padding: VH(14),
        fontSize: VW(16),
    },
    linkFont: {
        color: '#0052CC',
        marginTop: VH(24),
        marginBottom: VH(54),
        fontSize: VW(16),
        '& > span': {
            cursor: 'pointer'
        }
    },
    errorAlert: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(24)
    }
});

function Login(props) {
    const {
        classes,
    } = props


    useEffect(() => {
        localStorage.clear()
        let groundBox = document.getElementById('loginGround')
        localStorage.setItem('innerWidth', groundBox.getBoundingClientRect().width)
        localStorage.setItem('innerHeight', groundBox.getBoundingClientRect().height)
    }, [])

    const [userObject, setUserObject] = useState(qs.parse(localStorage.getItem('userObject')))


    // password display
    const [showPassword, setShowPassword] = useState(true);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    // login api
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()
    const login = () => {
        if (username === '') {
            setErrorContent(errorMessage.nameEmptyError)
            setRrrorOpen(true)
        } else if (formatCheck.UserName.test(username)) {
            setErrorContent(errorMessage.nameFormatError)
            setRrrorOpen(true)
        } else if (password === '') {
            setErrorContent(errorMessage.passwordNull)
            setRrrorOpen(true)
        } else {
            setRrrorOpen(false)
            showLoading()
            // signInWithEmail(username, password)
            //     .then((res) => {
            //         if (res.code == 'newPasswordRequired') {
            //             navigate(routerNames.NewPassword, { state: { email: username } })
            //         } else {
            //             localStorage.setItem("username", username)
            //             localStorage.setItem("password", password)
            //             localStorage.setItem("access_token", res.data.idToken.jwtToken)
            //             localStorage.setItem("passwordUpdateToken", res.data.accessToken.jwtToken)
            //             localStorage.setItem("refresh_token", res.data.refreshToken.token)
            //             navigate(routerNames.MemberManagement)
            //         }
            //         hideLoading()
            //     })
            //     .catch((err) => {
            //         if (err.message == 'Incorrect username or password.') {
            //             setErrorContent(errorMessage.E00001)
            //             setRrrorOpen(true)
            //             hideLoading()
            //         } else {
            //             setErrorContent(err.message)
            //             setRrrorOpen(true)
            //             hideLoading()
            //         }
            //     })

            PocLogin({username, password})                
                .then((res) => {
                    if (res.status != 200) {
                        setErrorContent(errorMessage.E00001)
                        setRrrorOpen(true)
                        hideLoading()
                    } else {
                        localStorage.setItem("access_token", res.data.access_token)
                        PocInspection({token: res.data.access_token}).then(resInspection => {
                            if (resInspection.data.active) {
                                let userId = resInspection.data.sub
                                PocUserInfo({userid: userId}).then(resInfo => {
                                    let hasAuth = false;
                                    if (resInfo.data && resInfo.data.roles && resInfo.data.roles.length > 0) {
                                        resInfo.data.roles.forEach(item => {
                                            if (item.roleName == "juku") {
                                                hasAuth = true;
                                            }
                                        })
                                    }
                                    if (hasAuth) {
                                        localStorage.setItem("username", username)
                                        localStorage.setItem("access_token", res.data.access_token)
                                        localStorage.setItem("refresh_token", res.data.refresh_token)
                                        navigate(routerNames.Empty)
                                        hideLoading()
                                    } else {
                                        localStorage.setItem("access_token", "")
                                        setErrorContent(errorMessage.E00006)
                                        setRrrorOpen(true)
                                        hideLoading()
                                    }
                                }).catch(e=>{
                                    localStorage.setItem("access_token", "")
                                    setErrorContent(errorMessage.E00001)
                                    setRrrorOpen(true)
                                    hideLoading()
                                })
                            } else {
                                localStorage.setItem("access_token", "")
                                setErrorContent(errorMessage.E00001)
                                setRrrorOpen(true)
                                hideLoading()
                            }
                        }).catch(err => {
                            localStorage.setItem("access_token", "")
                            setErrorContent(errorMessage.E00001)
                            setRrrorOpen(true)
                            hideLoading()
                        })
                    }                    
                })
                .catch((err) => {
                    setErrorContent(errorMessage.E00001)
                    setRrrorOpen(true)
                    hideLoading()
                })

            let params = {
                email: localStorage.getItem("username")
            }
        }
    }


    // to sendCode
    const toSendCode = () => {
        navigate(routerNames.SendCode)
    }


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    return (
        <div className={classes.groundBlock} id='loginGround'>
            <div className={classes.LoginView}>
                <div className={classes.tittleIcon}>教育PDS実証実験用アプリ</div>
                <div className={classes.tittleIcon}>ログイン</div>
                {errorOpen && <div className={classes.errorAlert}>
                    <div style={{ width: VW(400) }}>
                        <Common_errorAlert
                            open={errorOpen}
                            setRrrorOpen={setRrrorOpen}
                            errorContent={errorContent}
                        />
                    </div>
                </div>}
                <div className={classes.label}>
                    <div style={{ width: VW(400), textAlign: 'left', fontSize: VW(14), marginBottom: VH(8) }}>ユーザー名 <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                </div>
                <div className={classes.text}>
                    <TextField
                        name="username"
                        autoComplete="username"
                        sx={{ width: VW(400) }}
                        placeholder="ユーザー名を入力してください"
                        onChange={(event) => { setUsername(event.target.value) }}
                        value={username}
                        InputProps={{
                            className: classes.input,
                            classes: {
                                input: classes.inputContent
                            }
                        }}
                    />
                </div>
                <div className={classes.label}>
                    <div style={{ width: VW(400), textAlign: 'left', fontSize: VW(14), marginBottom: VH(8) }}>パスワード <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                </div>
                <div className={classes.text}>
                    <TextField
                        name="password"
                        autoComplete="password"
                        sx={{ width: VW(400) }}
                        type={showPassword ? 'password' : 'text'}
                        placeholder="パスワードを入力してください"
                        onChange={(event) => { setPassword(event.target.value) }}
                        value={password}
                        InputProps={{
                            className: classes.input,
                            endAdornment: (
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            ),
                            className: classes.input,
                            classes: {
                                input: classes.inputContent
                            }
                        }}
                    />
                </div>

                <Button
                    className={classes.loginButton}
                    variant="contained"
                    style={{ width: VW(400), height: VH(48) }}
                    onClick={login}
                >ログイン
                </Button>

                <div className={classes.linkFont}>
                    {/* <span onClick={toSendCode}>パスワードをお忘れの場合</span> */}
                </div>
            </div>
        </div>
    )
}

Login.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Login);