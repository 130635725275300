import { Close } from "@mui/icons-material";
import { Checkbox, IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Button, DatePicker, Input, Modal } from 'antd';
import locale from "antd/es/date-picker/locale/ja_JP";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { ApiTenantAdd } from '../../network/api';
import { errorMessage } from "../../wordsFile/wordsFile";
import Common_errorAlert from "../common/Common_errorAlert";
import log from "../function/log";
import { VH, VW } from "../function/sizeAdapter";
import sortNumbers from "../function/sortNumbers";
import AddIcon from "../icon/AddIcon";

dayjs.extend(utc)

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    updateInput: {
        width: VW(1008),
        '& > div:not(:last-child)': {
            width: '100%',
            height: VH(74),
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div:not(:last-child) > div': {
            padding: '0 0.78vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16)
        },
        '& > div:not(:last-child) > div > Input': {
            width: '100%',
            height: VH(50),
            fontSize: VW(16)
        },
        '& > div:not(:last-child) > div:nth-child(1)': {
            width: VW(160),
            height: '100%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(1) > div:nth-child(2)': {
            width: VW(848),
            height: '100%'
        },
        '& > div:nth-child(2) > div:nth-child(2)': {
            width: VW(848),
            height: '100%'
        },
        '& > div:last-child': {
            width: '100%',
            height: VH(50),
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: '1vw',
            '& > div': {
                cursor: 'pointer',
                color: theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '0.78vw',
            }
        },
    },
    InputAdministrator: {
        width: '100%',
        height: VH(74),
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16)
        },
        '& > div:nth-child(1)': {
            width: VW(160),
            height: '100%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
            padding: '0 0.78vw',
        },
        '& > div:nth-child(2)': {
            width: VW(128),
            padding: '0 0.39vw 0 0.78vw',
        },
        '& > div:nth-child(3)': {
            width: VW(128),
            padding: '0 0.78vw 0 0.39vw',
        },
        '& > div:nth-last-child(2)': {
            width: VW(160),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
            padding: '0 0.78vw',
        },
        '& > div:nth-child(5)': {
            width: VW(432),
            padding: '0 0.78vw',
        },
        '& > div > Input': {
            width: '100%',
            height: VH(50),
            fontSize: VW(16)
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    },
    // appSelect: {
    //     width: VW(1008),
    //     height: 'auto',
    //     borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
    //     display: 'flex',
    //     '& > div': {
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'flex-start',
    //         fontSize: VW(16)
    //     },
    //     '& > div:nth-child(1)': {
    //         padding: '0 0.78vw',
    //         width: VW(160),
    //         backgroundColor: '#F8F8F8',
    //         fontWeight: 'bold',
    //         whiteSpace: 'nowrap'
    //     },
    //     '& > div:nth-child(2)': {
    //         display: 'block',
    //         marginBottom: '10px',
    //         width: '82%'
    //     },
    //     '& > div:nth-child(2) > div:nth-child(1)': {
    //         height: VH(30)
    //     },
    //     '& > div:nth-child(2) > div:nth-child(2)': {
    //         display: 'flex',
    //         flexWrap: 'wrap'
    //     },
    //     '& > div:nth-child(2) > div:nth-child(2) > div': {
    //         width: VW(190),
    //         height: VH(30)
    //     }
    // },
    // appSelectItem: {
    //     '& > input': {
    //         marginLeft: '0'
    //     }
    // }
});

function Tenant_add(props) {
    const {
        classes,
        open,
        onClose,
        administratorList,
        setAdministratorList,
        getList,
        //selectedApp,
        //setSelectedApp,
        //appList,
        handleCheckboxChange
    } = props


    useEffect(() => {
        if (!open) {
            let nullList = [
                { surname: '', name: '', email: '' },
                { surname: '', name: '', email: '' },
                { surname: '', name: '', email: '' }
            ]
            setAdministratorList([...nullList])
            setTenantName('')
            // setStartValue('')
            // setStartDay('')
            setEndValue('')
            setEndDay('')
            //setSelectedApp([])
            setRrrorOpen(false)
        }
    }, [open])

    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // add 管理者
    const addAdministratorListItem = () => {
        administratorList.push({ surname: '', name: '', email: '' })
        setAdministratorList([...administratorList])
    }


    // update data
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> テナント名
    const [tenantName, setTenantName] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 契約開始日
    // const [startValue, setStartValue] = useState("");
    // const [startDay, setStartDay] = useState('')
    // const changeStartDay = (value, dateString) => {
    //     setStartDay(value)
    //     setStartValue(dateString);
    //     log(dateString)
    // };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 契約終了日
    const [endValue, setEndValue] = useState("");
    const [endDay, setEndDay] = useState('')
    const changeEndDay = (value, dateString) => {
        setEndDay(value)
        setEndValue(dateString);
        // console.log(dateString);
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 管理者 list
    const updateAdministratorList = (item, index, flag) => {
        administratorList[index][flag] = item
        setAdministratorList([...administratorList])
        log(administratorList)
    }

    // add api
    const addItem = () => {
        let InformationFlag = false
        administratorList.map(item => {
            if ((item.surname === '' && (item.name != '' || item.email != '')) || (item.name === '' && (item.surname != '' || item.email != '')) || (item.email === '' && (item.name != '' || item.surname != ''))) {
                InformationFlag = true
            }
        })
        if (tenantName === '') {
            setErrorContent(errorMessage.tenantNameNull)
            setRrrorOpen(true)
        }
        // else if((startValue === '' && endValue != '') || dayjs(startValue) >= dayjs(endValue)){
        //     setErrorContent(errorMessage.startEndDayNull)
        //     setRrrorOpen(true)
        // }
        else if (InformationFlag) {
            setErrorContent(errorMessage.administratorNull)
            setRrrorOpen(true)
        } else {
            let userList = []
            administratorList.map(item => {
                if (item.surname != '') {
                    userList.push(item)
                }
            })
            // let indexZero = selectedApp.indexOf(0);
            // if (!(indexZero < 0)) {
            //     selectedApp.splice(indexZero, 1);
            // }
            // selectedApp.sort(sortNumbers);
            let params = {
                tenant_name: tenantName,
                user_masters: userList,
                tenant_contract_date_end: endValue === '' ? '' : endValue
            }
            ApiTenantAdd(params).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    log(res.data)
                    // if(res.data.error_email.length > 0){
                    //     res.data.error_email.map(item => {
                    //         message.error(item.email + 'へのメール送信に失敗しました。入力内容をご確認ください。')
                    //     })
                    // }
                    if (res.data.error_email.length > 0) {
                        getList(res.data.error_email)
                    } else {
                        getList('add')
                    }
                    onClose()
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })

        }
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div className={classes.tableTitle}>
                <AddIcon />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>テナントの登録</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
            />
            <div className={classes.updateInput}>
                <div>
                    <div>テナント名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: '0.5vw' }}>*</span></div>
                    <div>
                        <Input
                            value={tenantName}
                            onChange={(event) => setTenantName(event.target.value)}
                            maxLength={60}
                        />
                    </div>
                </div>
                <div style={{ display: 'none' }}>
                    {/* <div>契約開始日</div>
                    <div>
                        <DatePicker
                            style={{width:'25%', height:'80%'}}
                            onChange={changeStartDay}
                            placeholder=''
                            format="YYYY/MM/DD"
                            value = {startDay}
                            locale = { locale }
                        />
                    </div> */}
                </div>
                <div>
                    <div>契約終了日</div>
                    <div>
                        <DatePicker
                            style={{ width: VW(220), height: VH(50), fontSize: VW(16) }}
                            onChange={changeEndDay}
                            placeholder=''
                            format="YYYY/MM/DD"
                            value={endDay}
                            locale={locale}
                        />
                    </div>
                </div>
                {administratorList.map((item, index) => {
                    return (
                        <div className={classes.InputAdministrator} key={index}>
                            <div>管理者</div>
                            <div>
                                <Input
                                    placeholder="姓"
                                    value={item.surname}
                                    onChange={(event) => updateAdministratorList(event.target.value, index, 'surname')}
                                    maxLength={30}
                                />
                            </div>
                            <div>
                                <Input
                                    placeholder="名"
                                    value={item.name}
                                    onChange={(event) => updateAdministratorList(event.target.value, index, 'name')}
                                    maxLength={30}
                                />
                            </div>
                            <div>メールアドレス</div>
                            <div>
                                <Input
                                    value={item.email}
                                    onChange={(event) => updateAdministratorList(event.target.value, index, 'email')}
                                    maxLength={200}
                                />
                            </div>
                        </div>
                    )
                })}
                <div>
                    <div onClick={addAdministratorListItem} style={{ fontSize: VW(16) }}>
                        <AddIcon />&ensp;管理者を追加する
                    </div>
                </div>
            </div>

            <div className={classes.buttonGroup}>
                <Button onClick={onClose}>キャンセル</Button>
                <Button onClick={addItem}>登録</Button>
            </div>
        </Modal>
    )
}

Tenant_add.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Tenant_add);