import { VW, VH } from "../../componets/function/sizeAdapter"
function HeaderBarIcon() {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22">
            <path d="M3 16C3 15.4477 3.44772 15 4 15H13C13.5523 15 14 15.4477 14 16C14 16.5523 13.5523 17 13 17H4C3.44772 17 3 16.5523 3 16Z" fill="black" />
            <path d="M3 6C3 5.44772 3.44772 5 4 5H18C18.5523 5 19 5.44772 19 6C19 6.55228 18.5523 7 18 7H4C3.44772 7 3 6.55228 3 6Z" fill="black" />
            <path d="M3 11C3 10.4477 3.44772 10 4 10H18C18.5523 10 19 10.4477 19 11C19 11.5523 18.5523 12 18 12H4C3.44772 12 3 11.5523 3 11Z" fill="black" />
            <mask id="mask0_5949_1443" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="5" width="16" height="12">
                <path d="M3 16C3 15.4477 3.44772 15 4 15H13C13.5523 15 14 15.4477 14 16C14 16.5523 13.5523 17 13 17H4C3.44772 17 3 16.5523 3 16Z" fill="white" />
                <path d="M3 6C3 5.44772 3.44772 5 4 5H18C18.5523 5 19 5.44772 19 6C19 6.55228 18.5523 7 18 7H4C3.44772 7 3 6.55228 3 6Z" fill="white" />
                <path d="M3 11C3 10.4477 3.44772 10 4 10H18C18.5523 10 19 10.4477 19 11C19 11.5523 18.5523 12 18 12H4C3.44772 12 3 11.5523 3 11Z" fill="white" />
            </mask>
            <g mask="url(#mask0_5949_1443)">
                <rect width="22" height="22" fill="#8A939C" />
            </g>
        </svg>
    )
}

export default HeaderBarIcon;