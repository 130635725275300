import React, { useState, useEffect } from "react";
import {
    Box
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import { Link } from "react-router-dom";
import { VW, VH } from "../function/sizeAdapter"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ApiAdminMenu } from "../../network/api";
import log from '../function/log'
import { useNavigate } from "react-router-dom";



const styles = (theme) => ({
    menuLink: {
        textDecoration: "none",
    },
    menuItem: {
        width: '100%',
        height: VH(56),
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #DDD',
        position: 'relative',
        '& > div:nth-child(1)': {
            marginLeft: VW(16),
            width: VW(6),
            height: VH(20)
        },
        '& > div:nth-child(2)': {
            marginLeft: VW(16),
            fontSize: VW(16),
            color: 'black'
        }
    },
    classTitle: {
        width: '100%',
        height: VH(37),
        display: 'flex',
        alignItems: 'center',
        paddingLeft: VW(16),
        fontSize: VW(16),
        borderBottom: '1px solid #DDD',
    },
});

function LeftMenu(props) {
    const {
        classes,
        selectFlag,
        tenant,
        store
    } = props

    const navigate = useNavigate()


    // menu list
    const [menuItems, setMenuItems] = useState([])
    // jump list
    const [jumpItems, setJumpItems] = useState([])
    // top title
    const [topTitle, setTopTitle] = useState('')
    // bottom title
    const [bottomTitle, setBottomTitle] = useState('')


    useEffect(() => {
        if (tenant) {
            ApiAdminMenu().then(res => {
                let resData = res.data
                if (res.status === 200) {
                    log(resData)
                    log(Object.keys(resData.data))
                    let firstKey = Object.keys(resData.data)[0]
                    // title value
                    setTopTitle(firstKey)
                    if (Object.keys(resData.data).length > 1) {
                        setBottomTitle(Object.keys(resData.data)[1])
                    }
                    // menus data
                    let firstList = []
                    resData.data[`${firstKey}`].map(item => {
                        if (item.menu_url == '/tenant_management') {
                            firstList.push({
                                name: item.menu_name,
                                link: routerNames.TenantManagement,
                                selectColor: selectFlag === routerNames.TenantManagement ? '#2E3ECF' : '#AAA',
                            })
                        }
                        if (item.menu_url == '/project_management') {
                            firstList.push({
                                name: item.menu_name,
                                link: routerNames.ProjectManagement,
                                selectColor: selectFlag === routerNames.ProjectManagement ? '#2E3ECF' : '#AAA',
                            })
                        }
                        if (item.menu_url == '/project_detail') {
                            firstList.push({
                                name: item.menu_name,
                                link: routerNames.ProjectDetail,
                                selectColor: selectFlag === routerNames.ProjectDetail ? '#2E3ECF' : '#AAA',
                            })
                        }
                        if (item.menu_url == '/member_management') {
                            firstList.push({
                                name: item.menu_name,
                                link: routerNames.MemberManagement,
                                selectColor: selectFlag === routerNames.MemberManagement ? '#2E3ECF' : '#AAA',
                            })
                        }
                        if (item.menu_url == '/master_management') {
                            firstList.push({
                                name: item.menu_name,
                                link: routerNames.MasterManagement,
                                selectColor: selectFlag === routerNames.MasterManagement ? '#2E3ECF' : '#AAA',
                            })
                        }
                    })
                    setMenuItems([...firstList])
                    navigate(firstList[0].link)
                    if (Object.keys(resData.data).length > 1) {
                        let lastKey = Object.keys(resData.data)[1]
                        setJumpItems(resData.data[`${lastKey}`])
                    }

                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    alert()
                }
            }).catch(err => {

            })
        }
        // TOKENを変換するため、マルチテナントのAPIを利用できなくなりました。直接にPC画面のリンクを追加する
        setBottomTitle("アプリケーション")
        setJumpItems([
            {
                "menu_id": null,
                "menu_name": "成績管理アプリ",
                "menu_category": 2,
                "sort": null,
                "menu_url": process.env.REACT_APP_SCORE_APP //"https://dev.d3gyb3tshtota1.amplifyapp.com/"
            }
        ])
    }, [tenant, store])



    // menu change
    const changeMenu = (item, index) => {
        if (item.selectColor === '#2E3ECF') {
            return
        } else {
            menuItems.map(element => {
                element.selectColor = '#AAA'
            })
            for (var i = 0; i < menuItems.length; i++) {
                menuItems[index].selectColor = '#2E3ECF'
            }
            setMenuItems([...menuItems])
        }
    }


    useEffect(() => {
        menuItems.map(element => {
            if (element.link === selectFlag) {
                element.selectColor = '#2E3ECF'
            } else {
                element.selectColor = '#AAA'
            }
        })
        setMenuItems([...menuItems])
    }, [selectFlag])


    const toGlub = (url) => {
        const newUrl = new URL(url);
        const newWindow = window.open(newUrl)
        window.addEventListener('message', () => {
            newWindow.postMessage({ type: 'message', data: { access_token: localStorage.getItem('access_token'), refresh_token: localStorage.getItem("refresh_token"), username: localStorage.getItem("username"), password: localStorage.getItem("password") } }, '*')
        })

        // const newUrl = new URL('http://localhost:3001');
        // const newWindow = window.open(newUrl)
        // window.addEventListener('message', () => {
        //     newWindow.postMessage({ type: 'message', data: { access_token: localStorage.getItem('access_token'), refresh_token: localStorage.getItem("refresh_token"), username: localStorage.getItem("username") } }, '*')
        // })
    }


    return (
        <>
            <div className={classes.classTitle}>{topTitle}</div>
            {
                menuItems.length > 0 && menuItems.map((item, index) => (
                    <Link
                        to={item.link}
                        key={index}
                        className={classes.menuLink}
                    >
                        <div
                            className={classes.menuItem}
                            key={index}
                            onClick={() => { changeMenu(item, index) }}
                        >
                            <div style={{ backgroundColor: item.selectColor }}></div>
                            <div>{item.name}</div>
                            <span style={{ height: '100%', display: 'flex', alignItems: 'center', position: 'absolute', right: VW(16) }}><KeyboardArrowRightIcon /></span>
                        </div>
                    </Link>
                ))
            }
            {bottomTitle && <div className={classes.classTitle} style={{ marginTop: VH(16) }}>{bottomTitle}</div>}
            {bottomTitle &&
                <>
                    {jumpItems.length > 0 && jumpItems.map((item, index) => {
                        return (
                            <div className={classes.menuItem} style={{ cursor: 'pointer' }} onClick={() => { toGlub(item.menu_url) }} key={index}>
                                <div style={{ backgroundColor: '#AAA' }}></div>
                                <div>{item.menu_name}</div>
                                <span style={{ height: '100%', display: 'flex', alignItems: 'center', position: 'absolute', right: VW(16) }}><KeyboardArrowRightIcon /></span>
                            </div>
                        )
                    })}
                </>
            }
        </>
    )
}

LeftMenu.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(LeftMenu);