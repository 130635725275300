import React, { useState, useEffect } from "react";
import {

} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, Select, DatePicker } from 'antd';
import AddIcon from "../icon/AddIcon";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { CaretDownOutlined } from '@ant-design/icons'
import Common_errorAlert from "../common/Common_errorAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ApiCompanyMemberAdd, ApiCompanyMemberAuthority } from "../../network/api";
import { errorMessage, formatCheck } from "../../wordsFile/wordsFile";



dayjs.extend(utc)
const { Option } = Select;
const { TextArea } = Input;


const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        },
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    updateInput: {
        width: VW(1008),
        '& > div': {
            width: '100%',
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div > div': {
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16),
            minHeight: VH(74),
        },
        '& > div > div > Input': {
            width: '100%',
            height: VH(50),
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div > TextArea': {
            width: '100%',
            fontSize: VW(16),
            borderRadius: "4px"
        },
        '& > div > div:nth-child(1)': {
            width: VW(240),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
        },
        '& > div > div:nth-child(2)': {
            height: '100%',
            width: VW(760),
        },
        '& > div:nth-last-child(2) > div > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        },
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function Member_add(props) {
    const {
        classes,
        open,
        onClose,
        getList
    } = props

    useEffect(() => {
        if (!open) {
            setFirstName('')
            setLastName('')
            setEmail('')
            setBelonging('')
            setAuthority(undefined)
            setRrrorOpen(false)
        } else {
            getOption()
        }
    }, [open])

    // 権限 option
    const getOption = () => {
        ApiCompanyMemberAuthority().then(res => {
            if (res.status === 200) {
                setRrrorOpen(false)
                log(res.data)
                setAuthorityOption(res.data.data)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }

    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')

    // data
    // 姓
    const [firstName, setFirstName] = useState('')
    // 名
    const [lastName, setLastName] = useState('')
    // メールアドレス
    const [email, setEmail] = useState('')
    // 所属
    const [belonging, setBelonging] = useState('')
    // 権限
    const [authority, setAuthority] = useState(undefined)
    const [authorityOption, setAuthorityOption] = useState([])


    // add api
    const toAdd = () => {
        if (firstName == '' || lastName == '' || email == '' || !authority) {
            setErrorContent('*の付く項目は、必須項目です。')
            setRrrorOpen(true)
        } else if (email != '' && !formatCheck.Email.test(email)) {
            setErrorContent(errorMessage.emailFormatError)
            setRrrorOpen(true)
        } else {
            let params = {
                surname: firstName,
                name: lastName,
                email: email,
                authority_id: authority,
                affiliation: belonging
            }
            log(params)
            ApiCompanyMemberAdd(params).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    log(res.data)
                    onClose()
                    getList('add')
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div>
                <div className={classes.tableTitle}>
                    <AddIcon size={VW(16.67)} />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>メンバーの登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.updateInput} style={{ marginTop: VH(13) }}>
                    <div>
                        <div>氏名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                        <div>
                            <Input
                                style={{ width: VW(168) }}
                                placeholder="姓"
                                value={firstName}
                                onChange={(event) => setFirstName(event.target.value)}
                                maxLength={30}
                            />
                            <Input
                                style={{ width: VW(168), marginLeft: VW(16) }}
                                placeholder="名"
                                value={lastName}
                                onChange={(event) => setLastName(event.target.value)}
                                maxLength={30}
                            />
                        </div>
                    </div>
                    <div>
                        <div>メールアドレス<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                        <div>
                            <Input
                                placeholder="メールアドレスを記入してください"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                maxLength={200}
                            />
                        </div>
                    </div>
                    <div>
                        <div>所属</div>
                        <div>
                            <Input
                                placeholder="所属を記入してください"
                                value={belonging}
                                onChange={(event) => setBelonging(event.target.value)}
                                maxLength={60}
                            />
                        </div>
                    </div>
                    <div>
                        <div>権限<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                        <div>
                            <Select
                                style={{ width: VW(360), display: 'flex', alignItems: 'center' }}
                                placeholder='権限を選択してください'
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                suffixIcon={<CaretDownOutlined style={{ color: 'black' }} />}
                                value={authority}
                                onChange={(event) => setAuthority(event)}
                                allowClear
                                onClear={() => { setAuthority(undefined) }}
                            >
                                {authorityOption.map((item, index) => {
                                    return (
                                        <Option style={{ fontSize: VW(16) }} key={index} value={item.authority_id}>{item.authority_name}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button onClick={toAdd}>登録</Button>
                </div>
            </div>
        </Modal >
    )
}

Member_add.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Member_add);