import {
    Box, Hidden
} from "@mui/material";
import { withStyles } from "@mui/styles";
import qs from 'qs';
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import HeaderBar from "../componets/bar/HeaderBar";
import LeftMenu from "../componets/bar/LeftMenu";
import { VH } from "../componets/function/sizeAdapter";
import MainRouters from "../routes/MainRouters";

const styles = (theme) => ({
    Box: {
        width: '100%',
        height: '100vh',
    },
    headerBar: {
        width: '100%',
        height: VH(56),
    },
    bodyMain: {
        width: '100%',
        display: 'flex',
    },
    menu: {
        transition: 'all 0.1s',
        overflow: 'hidden',
    },
    content: {
        height: 'calc(100vh - 56px)',
        transition: 'all 0.1s',
        overflowY: 'scroll',
        overflowY: 'overlay'
    },
});


function Home(props) {
    const {
        classes,
    } = props

    const navigate = useNavigate()

    const [selectFlag, setSelectFlag] = useState('/home/dashBoard')
    const [userObject, setUserObject] = useState(qs.parse(localStorage.getItem('userObject')))

    // open or close menu
    const [menuFlag, setMenuFlag] = useState(true)
    const changeMenuStatus = () => {
        setMenuFlag(!menuFlag)
    }
    const changeResources = () => {
        setUserObject(qs.parse(localStorage.getItem('userObject')))
    }

    useEffect(() => {
        window.devicePixelRatio = 1
        function handleResize() {
            var zoomLevel = window.devicePixelRatio

            let newWidth = zoomLevel * localStorage.getItem('innerWidth')
            localStorage.setItem('zoomWidth', newWidth)

            let newHeight = zoomLevel * localStorage.getItem('innerHeight')
            localStorage.setItem('zoomHeight', newHeight)
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    // header data
    const [tenant, setTenant] = useState()
    const [store, setStore] = useState()


    return (
        <Box className={classes.Box} id='father_Block' style={{ overflow: 'scroll', overflow: 'overlay' }}>
            <Box
                style={{
                    minWidth: Number(localStorage.getItem('zoomWidth')) ? Number(localStorage.getItem('zoomWidth')) : Number(localStorage.getItem('innerWidth')),
                    minHeight: Number(localStorage.getItem('zoomHeight')) ? Number(localStorage.getItem('zoomHeight')) : Number(localStorage.getItem('innerHeight')),
                }}>
                <Box className={classes.headerBar}>
                    <HeaderBar
                        changeMenuStatus={changeMenuStatus}
                        menuFlag={menuFlag}
                        userObject={userObject}
                        changeResources={changeResources}
                        tenant={tenant}
                        setTenant={setTenant}
                        store={store}
                        setStore={setStore}
                    />
                </Box>
                <Box className={classes.bodyMain}>
                    <Box className={classes.menu} style={{ width: menuFlag ? '17%' : '0' }}>
                        <LeftMenu
                            selectFlag={selectFlag}
                            tenant={tenant}
                            store={store}
                        />
                    </Box>
                    <Box className={classes.content} id='scroll_Block' style={{ width: menuFlag ? '83%' : '100%' }}>
                        <MainRouters
                            setSelectFlag={setSelectFlag}
                            userObject={userObject}
                            setUserObject={setUserObject}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

Home.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Home);