import AWS from "aws-sdk";

const S3_BUCKET = process.env.REACT_APP_BUCKET;
const REGION = process.env.REACT_APP_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
});


const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION
});


export function UploadS3(item_id, file, type, callBack, newData) {
  let time = Math.floor(Date.now() / 1000);
  const params = {
    Body: file,
    Bucket: S3_BUCKET,
    Key: newData ? `headsculpture/${item_id}/${newData}/${file.name}` : `filemanage/${item_id}/${file.name}`,
    ContentType: `${type}`
  };

  s3.upload(params, (err, data) => {
    if (err) {
      callBack({ error: err.message });
    } else {
      callBack({ success: time });
    }
  }).on("httpUploadProgress", (progress) => {
    let per = Math.round(progress.loaded / progress.total * 10000) / 100;
    callBack({ progress: per });
  });
};


export function PropertyUploadS3(file, type, callBack, newData) {
  let time = Math.floor(Date.now() / 1000);
  const params = {
    Body: file,
    Bucket: S3_BUCKET,
    Key: `item_base_upload/${newData}/${file.name}`,
    ContentType: `${type}`
  };
  s3.upload(params, (err, data) => {
    if (err) {
      callBack({ error: err.message });
    } else {
      callBack({ success: time });
    }
  }).on("httpUploadProgress", (progress) => {
    let per = Math.round(progress.loaded / progress.total * 10000) / 100;
    callBack({ progress: per });
  });
};


export function FileUploadS3(item_id, file, type, callBack, uploadFile_Folder_name) {
  let newpath = ''
  if (uploadFile_Folder_name == '') {
    if (file.path.indexOf('/') != -1) {
      newpath = file.path.substring(1)
    } else {
      newpath = file.path
    }
  } else {
    if (file.path.indexOf('/') != -1) {
      newpath = String(uploadFile_Folder_name) + file.path
    } else {
      newpath = String(uploadFile_Folder_name) + '/' + file.path
    }
  }

  let time = Math.floor(Date.now() / 1000);
  const params = {
    Body: file,
    Bucket: S3_BUCKET,
    Key: `filemanage/${item_id}/${newpath}`,
    ContentType: `${type}`
  };
  s3.upload(params, (err, data) => {
    if (err) {
      callBack({ error: err.message });
    } else {
      callBack({ success: time });
    }
  }).on("httpUploadProgress", (progress) => {
    let per = Math.round(progress.loaded / progress.total * 10000) / 100;
    callBack({ progress: per });
  });
};
