import { Checkbox } from "@mui/material";
import { withStyles } from "@mui/styles";
import { DatePicker, Input, Select } from 'antd';
import locale from "antd/es/date-picker/locale/ja_JP";
import React from "react";
import { VH, VW } from "../function/sizeAdapter";
import AddIcon from "../icon/AddIcon";

const { Option } = Select;
const styles = (theme) => ({
    updateInput: {
        width: '100%',
        marginTop: '20px',
        '& > div:not(:last-child)': {
            width: '100%',
            height: VH(74),
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex'
        },
        '& > div:not(:last-child) > div': {
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16)
        },
        '& > div:not(:last-child) > div > Input': {
            width: '100%',
            height: '80%',
            fontSize: VW(16)
        },
        '& > div:not(:last-child) > div:nth-child(1)': {
            width: VW(160),
            height: '100%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(2) > div:nth-child(2)': {
            width: '82%',
            height: '100%'
        },
        '& > div:last-child': {
            width: '100%',
            height: VH(50),
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: VW(16),
            Left: VW(19),
            '& > div': {
                cursor: 'pointer',
                color: theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
            }
        }
    },
    itemAdministrator: {
        '& > div:nth-child(2)': {
            width: VW(168),
        },
        '& > div:nth-child(3)': {
            width: VW(160),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(4)': {
            width: '40%'
        },
        '& > div > Input': {
            width: '100%'
        }
    },
    inputAdministrator: {
        width: '100%',
        height: VH(74),
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        '& > div:nth-child(2)': {
            width: '12%'
        },
        '& > div:nth-child(3)': {
            width: '13%',
            paddingRight: '1%'
        },
        '& > div:nth-child(4)': {
            width: '18%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(5)': {
            width: '39%'
        },
        '& > div > Input': {
            width: '100%'
        }
    },
    itemCreateData: {
        width: '100%',
        height: VH(74),
        borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
        display: 'flex',
        '& > div': {
            paddingLeft: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: VW(16)
        },
        '& > div:nth-child(1)': {
            width: VW(160),
            height: '100%',
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        }
    },
    // appSelect: {
    //     width: '100%',
    //     height: 'auto',
    //     borderBottom: `1px solid ${theme.palette.themeBorder.main}`,
    //     display: 'flex',
    //     '& > div': {
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'flex-start',
    //         fontSize: VW(16)
    //     },
    //     '& > div:nth-child(1)': {
    //         padding: '0 0.78vw',
    //         width: VW(160),
    //         backgroundColor: '#F8F8F8',
    //         fontWeight: 'bold',
    //         whiteSpace: 'nowrap'
    //     },
    //     '& > div:nth-child(2)': {
    //         display: 'block',
    //         marginBottom: '10px',
    //         width: '82%'
    //     },
    //     '& > div:nth-child(2) > div:nth-child(1)': {
    //         height: VH(30)
    //     },
    //     '& > div:nth-child(2) > div:nth-child(2)': {
    //         display: 'flex',
    //         flexWrap: 'wrap'
    //     },
    //     '& > div:nth-child(2) > div:nth-child(2) > div': {
    //         width: VW(190),
    //         height: VH(30)
    //     }
    // },
    // appSelectItem: {
    //     '& > input': {
    //         marginLeft: '0'
    //     }
    // }
});

function TenantManagementDetail_input(props) {
    const {
        classes,
        falseData,
        tenantName,
        setTenantName,
        endDay,
        changeEndDay,
        administratorList,
        updateAdministratorList,
        addAdministratorListItem,
        // selectedApp,
        // appList,
        handleCheckboxChange
    } = props

    return (
        <div>
            <div className={classes.updateInput}>
                <div>
                    <div>テナントCD</div>
                    <div>{falseData.tenant_id}</div>
                </div>
                <div>
                    <div>テナント名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: '0.5vw' }}>*</span></div>
                    <div>
                        <Input
                            value={tenantName}
                            onChange={(event) => { setTenantName(event.target.value) }}
                            maxLength={60}
                        />
                    </div>
                </div>
                <div style={{ display: 'none' }}>
                    {/* <div>契約開始日</div>
                    <div>
                        <DatePicker
                            style={{width:'100%', height:'80%'}}
                            placeholder=""
                            value = { startDay }
                            format="YYYY/MM/DD"
                            locale = { locale }
                            onChange={ changeStartDay }
                        />
                    </div> */}
                </div>
                <div>
                    <div>契約終了日</div>
                    <div>
                        <DatePicker
                            style={{ width: '100%', height: '80%', fontSize: VW(16) }}
                            placeholder=""
                            value={endDay}
                            format="YYYY/MM/DD"
                            locale={locale}
                            onChange={changeEndDay}
                        />
                    </div>
                </div>
                {falseData.user_master.map((item, index) => {
                    return (
                        <div key={index} className={classes.itemAdministrator}>
                            <div>管理者</div>
                            <div>{item.surname + item.name}</div>
                            <div>メールアドレス</div>
                            <div>{item.email}</div>
                        </div>
                    )
                })}
                {administratorList.length > 0 && administratorList.map((item, index) => {
                    return (
                        <div key={index} className={classes.inputAdministrator}>
                            <div>管理者</div>
                            <div>
                                <Input
                                    placeholder="姓"
                                    value={item.surname}
                                    onChange={(event) => updateAdministratorList(event.target.value, index, 'surname')}
                                    maxLength={30}
                                />
                            </div>
                            <div>
                                <Input
                                    placeholder="名"
                                    value={item.name}
                                    onChange={(event) => updateAdministratorList(event.target.value, index, 'name')}
                                    maxLength={30}
                                />
                            </div>
                            <div>メールアドレス</div>
                            <div>
                                <Input
                                    value={item.email}
                                    onChange={(event) => updateAdministratorList(event.target.value, index, 'email')}
                                    maxLength={200}
                                />
                            </div>
                        </div>
                    )
                })}
                <div>
                    <div onClick={addAdministratorListItem} style={{ fontSize: VW(16) }}>
                        <AddIcon />&ensp;管理者を追加する
                    </div>
                </div>
            </div>
            {/* <div className={classes.appSelect}>
                <div>アプリケーション</div>
                <div>
                    <div key={0} class={classes.appSelectItem}>
                        <Checkbox
                            sx={{
                                color: "#DDDDDD",
                                marginLeft: '0',
                                '&.MuiSvgIcon-root': {
                                    fontSize: VW(21)
                                }
                            }}
                            id="app-0"
                            checked={selectedApp.includes(0)}
                            onChange={() => handleCheckboxChange(0)}
                        />
                        <label htmlFor="app-0">一括</label>
                    </div>
                    <div>
                        {appList && appList.map((app, index) => (
                            <div key={index} class={classes.appSelectItem}>
                                <Checkbox
                                    sx={{
                                        color: "#DDDDDD",
                                        '&.MuiSvgIcon-root': {
                                            fontSize: VW(21),
                                            color: 'red'
                                        }
                                    }}
                                    id={`app-${index}`}
                                    checked={selectedApp.includes(app.application_id)}
                                    onChange={() => 
                                        
                                        boxChange(app.application_id)}
                                />
                                <label htmlFor={`app-${index}`}>{app.application_name}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </div> */}
            <div className={classes.itemCreateData}>
                <div>登録日</div>
                <div>{falseData.create_date}</div>
            </div>
        </div >
    )
}

TenantManagementDetail_input.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(TenantManagementDetail_input);