import React, { useEffect, useState, useRef } from "react";
import {

} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import { Button, Input } from 'antd'
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useLocation } from "react-router-dom";
import log from "../../componets/function/log";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import Common_successAlert from "../../componets/common/Common_successAlert";
import { ApiCompanyMemberUpdate, ApiCompanyMemberDetail, ApiCompanyMemberExit, ApiCompanyMemberAuthority } from '../../network/api'
import { VW, VH } from "../../componets/function/sizeAdapter"
import { ArrowForwardIos } from "@mui/icons-material";
import Member_delete from "../../componets/dialog/Member_delete";
import MenuHeader_member from "../../componets/icon/MenuHeader_member";
import Member_item from "../../componets/HtmlBody/Member_item";
import Member_input from "../../componets/HtmlBody/Member_input";

dayjs.extend(utc)

const styles = (theme) => ({
    ground: {
        width: '100%',
        paddingRight: VW(24),
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24),
            paddingRight: VW(24)
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8),
        display: 'flex',
        alignItems: 'center',
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        },
    },
    updateDeleteBlock: {
        position: 'absolute',
        right: 0,
        display: 'flex',
        alignItems: 'center',
        marginTop: VH(16),
        justifyContent: 'flex-end',
        '& > Button': {
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& > Button:nth-child(2)': {
            color: '#E60024',
        }
    },
    bottomButton: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VW(40),
        marginBottom: VW(40),
        '& > Button': {
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function MemberManagementDetail(props) {
    const {
        classes,
        setSelectFlag,
    } = props


    useEffect(() => {
        setSelectFlag(routerNames.MemberManagementDetail)
        log(state)
        getDetailData()
    }, [setSelectFlag])


    const authority_id = localStorage.getItem("authority_id")


    const location = useLocation();
    const { state } = location;


    const [updateFlag, setUpdateFlag] = useState(false)


    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')



    // detailed list data
    const [detailedData, setDetailedData] = useState(state)

    // 権限
    const [authority, setAuthority] = useState(state.authority_id)
    const [authorityOption, setAuthorityOption] = useState([])

    const changeDetailData = (value, key) => {
        detailedData[key] = value
        setDetailedData({ ...detailedData })
    }

    // 編集
    const changeUpdateFlag = () => {
        let param = {
            user_authority_id: detailedData.user_authority_id
        }
        ApiCompanyMemberExit(param).then(res => {
            if (res.status === 200) {
                ApiCompanyMemberAuthority().then(resO => {
                    if (resO.status === 200) {
                        setRrrorOpen(false)
                        log(resO.data)
                        setAuthorityOption(resO.data.data)
                        setUpdateFlag(true)
                    } else if (resO.status >= 300 && resO.status < 500 && resO.status != 401) {
                        log(resO)
                        setRrrorOpen(true)
                        setErrorContent(resO.data.detail.toString())
                    }
                }).catch(err => {

                })
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }


    // add api
    const toUpate = () => {
        document.getElementById('father_Block').scrollTop = 0;
        let params = {
            user_authority_id: detailedData.user_authority_id,
            authority_id: authority,
            affiliation: detailedData.affiliation
        }
        log(params)
        ApiCompanyMemberUpdate(params).then(res => {
            if (res.status === 200) {
                getDetailData('alert')
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }

    // detail api
    const getDetailData = (flag) => {
        let detailParam = {
            user_authority_id: detailedData.user_authority_id
        }
        ApiCompanyMemberDetail(detailParam).then(resDetail => {
            let detail = resDetail.data
            if (resDetail.status == 200) {
                log(detail)
                detail.data.allName = detail.data.surname + detail.data.name
                setDetailedData(detail.data)
                if (flag == 'alert') {
                    setRrrorOpen(false)
                    setSuccessOpen(true)
                    setSuccessContent('更新が完了しました。')
                    setUpdateFlag(false)
                }
            } else if (resDetail.status >= 300 && resDetail.status < 500 && resDetail.status != 401) {
                log(detail)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(resDetail.data.detail.toString())
            }
        }).catch(errDetail => {

        })
    }

    // delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteOpen(true)
    }

    // キャンセル
    const backItem = () => {
        setUpdateFlag(false)
        getDetailData()
    }


    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}><span style={{ cursor: 'pointer' }} onClick={() => { window.history.back() }}>メンバー管理</span><ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>メンバー情報</div>
            <div className={classes.tableTitle}>
                <MenuHeader_member />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>{updateFlag ? 'メンバー情報の編集' : 'メンバー情報'}</div>
                <div className={classes.updateDeleteBlock}>
                    {!updateFlag && authority_id != 3 && authority_id != 5 &&
                        <>
                            <Button style={{ padding: 0, marginRight: VW(24) }} type="text" icon={<CreateIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={changeUpdateFlag}>&nbsp;編集</Button>
                            <Button style={{ padding: 0, display: ['1', '2', '4'].includes(authority_id ? '' : 'none') }} type="text" danger icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openDeleteDialog}>&nbsp;削除</Button>
                        </>
                    }
                </div>
            </div>
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
                marginTop={VH(12)}
            />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginTop={VH(12)}
            />
            {!updateFlag ?
                <Member_item
                    detailedData={detailedData}
                />
                :
                <Member_input
                    detailedData={detailedData}
                    changeDetailData={changeDetailData}
                    authority={authority}
                    setAuthority={setAuthority}
                    authorityOption={authorityOption}
                />
            }
            <div className={classes.bottomButton}>
                {!updateFlag ?
                    <Button onClick={() => { window.history.back() }}>メンバー管理へ戻る</Button>
                    :
                    <>
                        <Button onClick={backItem}>キャンセル</Button>
                        <Button onClick={toUpate}>更新</Button>
                    </>
                }
            </div>
            <Member_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={[detailedData]}
                historyFlag={true}
            />
        </div>
    )
}

MemberManagementDetail.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(MemberManagementDetail);