import { Checkbox } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Button } from 'antd';
import React from "react";
import { ApiTenantReinvite } from '../../network/api';
import log from "../function/log";
import { VH, VW } from "../function/sizeAdapter";
const styles = (theme) => ({
    contentBlock: {
        width: '100%',
        marginTop: VH(20),
        '& > div': {
            borderTop: `1px solid ${theme.palette.themeBorder.main}`,
            display: 'flex',
        },
        '& > div:not(:nth-last-child(0))': {
            height: VH(74)
        },
        '& > div > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: VW(16),
            fontSize: VW(16)
        },
        '& > div > div:nth-child(1)': {
            width: VW(160),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold'
        },
        '& > div:nth-child(1) > div:nth-child(2)': {
            width: '82%',
            height: '100%'
        },
        '& > div:last-child': {
            borderBottom: `1px solid ${theme.palette.themeBorder.main}`
        },
    },
    administratorBlock: {
        '& > div:nth-child(2)': {
            width: VW(168),
        },
        '& > div:nth-child(3)': {
            width: VW(160),
            backgroundColor: '#F8F8F8',
            fontWeight: 'bold',
            overflow: 'hidden'
        },
        '& > div:nth-child(4)': {
            width: VW(336),
            overflow: 'hidden'
        },
        '& > div:nth-child(5)': {
            right: 0,
            fontSize: VW(16)
        },
        '& > div:nth-child(6)': {
            justifyContent: 'center',
            marginLeft: VW(16),
            padding: '0',
            '& > Button': {
                display: 'flex',
                right: 0,
                alignItems: 'center',
                justifyContent: 'center',
                width: VW(64),
                height: VH(32),
                backgroundColor: theme.palette.primary.main,
                fontSize: VW(14),
                fontWeight: 'bold'
            }
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    // appSelect: {
    //     width: '100%',
    //     display: 'flex',
    //     '& > div': {
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'flex-start',
    //         fontSize: VW(16)
    //     },
    //     '& > div:nth-child(1)': {
    //         backgroundColor: '#F8F8F8',
    //         fontWeight: 'bold',
    //         whiteSpace: 'nowrap'
    //     },
    //     '& > div:nth-child(2)': {
    //         display: 'block',
    //         margin: '10px 0',
    //         paddingLeft: '0px',
    //         width: '82%',
    //         display: 'flex',
    //         flexWrap: 'wrap'
    //     },
    //     '& > div:nth-child(2) > div': {
    //         width: VW(190),
    //         height: VH(30),
    //         display: 'flex',
    //         alignItems: 'center'
    //     }
    // }
});

function TenantManagementDetail_item(props) {
    const {
        classes,
        falseData,
        setRrrorOpen,
        setErrorContent,
        setSuccessOpen,
        setSuccessContent,
        // appList,
        // selectedApp
    } = props


    // send email api
    const sengEmailAgain = (item) => {
        log(item)
        let param = {
            email: item
        }
        ApiTenantReinvite(param).then(res => {
            if (res.status === 200) {
                log(res.data)
                setRrrorOpen(false)
                setSuccessOpen(true)
                setSuccessContent(res.data.data)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }


    return (
        <div className={classes.contentBlock}>
            <div>
                <div>テナントCD</div>
                <div>{falseData.tenant_id}</div>
            </div>
            <div>
                <div>テナント名</div>
                <div>{falseData.tenant_name}</div>
            </div>
            {/* <div>
                <div>契約開始日</div>
                <div>{falseData.tenant_contract_date_start}</div>
            </div> */}
            <div>
                <div>契約終了日</div>
                <div>{falseData.tenant_contract_date_end}</div>
            </div>
            {falseData.user_master && falseData.user_master.map((item, index) => {
                return (
                    <div key={index} className={classes.administratorBlock}>
                        <div>管理者</div>
                        <div>
                            <p>{item.surname + item.name}</p>
                        </div>
                        <div>メールアドレス</div>
                        <div>
                            <p>{item.email}</p>
                        </div>
                        <div style={{ color: item.status_id === 1 ? '#0052CC' : '#AAAAAA' }}>
                            {item.status_id === 1 ? '招待中' : '完了'}
                        </div>
                        {item.status_id === 1 &&
                            <div>
                                <Button type="primary" onClick={() => { sengEmailAgain(item.email) }}>再送信</Button>
                            </div>
                        }
                    </div>
                )
            })}
            {/* <div className={classes.appSelect}>
                <div>アプリケーション</div>
                <div>
                    {appList && appList.map((app, index) => (
                        <div key={index} className={classes.appSelectItem}>
                            <Checkbox
                                sx={{
                                    color: "#DDDDDD",
                                    '&.MuiSvgIcon-root': {
                                        fontSize: VW(21),
                                        color: 'red',
                                    }
                                }}
                                className={classes.checkBox}
                                id={`app-${index}`}
                                // key={.checked}
                                checked={selectedApp.includes(app.application_id)}
                                disabled
                            />
                            <label htmlFor={`app-${index}`}>{app.application_name}</label>
                        </div>
                    ))}
                </div>
            </div> */}
            <div>
                <div>登録日</div>
                <div>{falseData.create_date}</div>
            </div>
        </div>
    )
}

TenantManagementDetail_item.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(TenantManagementDetail_item);