import React, { useEffect, useState, Fragment } from "react";
import {
    Button,
    TextField,
    IconButton
} from "@mui/material";
import log from "../componets/function/log";
import { useNavigate, useLocation } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { routerNames } from '../wordsFile/wordsFile'
import { formatCheck, successMessage, errorMessage } from "../wordsFile/wordsFile";
import Common_errorAlert from "../componets/common/Common_errorAlert";
import Common_successAlert from "../componets/common/Common_successAlert";
import { ApiTokenForgotPassword, ApiTokenConfirmForgotPassword } from '../network/api'
import { message } from 'antd';
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { VW, VH } from "../componets/function/sizeAdapter"
import { ArrowBackIos } from "@mui/icons-material";
import projectLogo from '../componets/picture/projectLogo.png'
import { forgotPassword, confirmPassword } from "../network/cognito";
import { showLoading, hideLoading } from "../componets/function/loading";


const styles = (theme) => ({
    groundBlock: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        // borderBottom: '1px solid #EBE2E2',
        // borderRight: '1px solid #EBE2E2',
    },
    LoginView: {
        width: VW(680),
        backgroundColor: '#FFF',
        boxShadow: '0px 0.26vw 1.05vh 0.26vw #EAEFF3',
        textAlign: 'center',
        paddingTop: VH(20),
        marginTop: VH(10),
        position: 'absolute',
    },
    tittleIcon: {
        width: '100%',
        textAlign: 'center',
        fontSize: VW(24),
        fontWeight: 'bold'
    },
    annotateText: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(24),
        '& > div': {
            width: VW(400),
            textAlign: 'left',
            fontSize: VW(16),
        }
    },
    label: {
        width: '100%',
        display: "flex",
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(24)
    },
    text: {
        width: '100%',
        display: "flex",
        justifyContent: 'center',
        fontSize: VW(16),
    },
    loginButton: {
        backgroundColor: theme.palette.primary.main,
        marginTop: VH(40),
        fontSize: VW(16),
        fontWeight: 'bold',
        marginBottom: VH(20)
    },
    input: {
        height: VH(48),
        fontSize: VW(16),
    },
    inputContent: {
        padding: VH(14),
        fontSize: VW(16),
        fontSize: VW(16)
    },
    linkFont: {
        color: '#0052CC',
        marginTop: VH(12),
        fontSize: VW(16),
        '& > span': {
            cursor: 'pointer'
        }
    },
    sendAgainLink: {
        display: 'flex',
        justifyContent: 'center',
        '& > div': {
            width: VW(400),
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: VH(8),
        },
        '& > div > div': {
            color: theme.palette.primary.main,
            fontSize: VW(12),
            cursor: 'pointer',
        }
    },
    backLink: {
        fontSize: VW(12),
        color: theme.palette.primary.main,
        position: 'absolute',
        top: VH(12),
        left: VW(12),
        cursor: 'pointer'
    },
    errorAlert: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(24)
    }
});

function ChangePassword(props) {
    const {
        classes,
    } = props

    const navigate = useNavigate()
    const location = useLocation();
    const { state } = location;
    const [email, setEmail] = useState(state)


    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')



    // send code api
    const sendCodeAgain = () => {
        setRrrorOpen(false)
        showLoading()
        forgotPassword(email)
            .then(() => {
                hideLoading()
                setRrrorOpen(false)
                setSuccessContent(successMessage.codeSendSuccess)
                setSuccessOpen(true)
            })
            .catch((err) => {
                if (err.code === "LimitExceededException") {
                    setErrorContent(errorMessage.E00004)
                    setRrrorOpen(true)
                    hideLoading()
                } else {
                    setErrorContent(errorMessage.E00005)
                    setRrrorOpen(true)
                    hideLoading()
                }
            });
    }



    // password display
    const [showPasswordA, setShowPasswordA] = useState(true);
    const handleClickShowPasswordA = () => setShowPasswordA((show) => !show);
    const handleMouseDownPasswordA = (event) => {
        event.preventDefault();
    };
    const [showPasswordB, setShowPasswordB] = useState(true);
    const handleClickShowPasswordB = () => setShowPasswordB((show) => !show);
    const handleMouseDownPasswordB = (event) => {
        event.preventDefault();
    };

    // change password api
    const [code, setCode] = useState('')
    const [newPasswordA, setNewPasswordA] = useState('')
    const [newPasswordB, setNewPasswordB] = useState('')

    const toChangePassword = () => {
        var a = newPasswordA.match(/[^\x00-\xff]/g)
        if (code === '') {
            setErrorContent(errorMessage.E0010)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else if (newPasswordA === '') {
            setErrorContent(errorMessage.E0004)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else if (!formatCheck.Password.test(newPasswordA) || !formatCheck.Password.test(newPasswordB)) {
            setErrorContent(errorMessage.passwordFormatError)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else if (newPasswordA != newPasswordB) {
            setErrorContent(errorMessage.namePasswordDif)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else if (a != null) {
            setErrorContent(errorMessage.E0007)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else {
            setRrrorOpen(false)
            showLoading()
            confirmPassword(email, code, newPasswordA)
                .then(() => {
                    hideLoading()
                    navigate(routerNames.ChangeOK)
                })
                .catch((err) => {
                    if (err.message = 'Invalid verification code provided, please try again.') {
                        setErrorContent(errorMessage.E00002)
                        setRrrorOpen(true)
                        hideLoading()
                    } else if (err.message = 'Invalid session for the user.') {
                        setErrorContent(errorMessage.E00003)
                        setRrrorOpen(true)
                        hideLoading()
                    } else {
                        setErrorContent(err.message)
                        setRrrorOpen(true)
                        hideLoading()
                    }
                });
        }
    }


    return (
        <div className={classes.groundBlock}>
            <div className={classes.LoginView}>
                <img
                    style={{ height: VH(70), objectFit: "contain", marginBottom: VH(10) }}
                    src={projectLogo}
                />
                <div className={classes.backLink} onClick={() => { window.history.back() }}><div style={{ display: 'flex', alignContent: "center", alignItems: "center" }}><ArrowBackIos style={{ width: VW(16), height: VW(16) }}></ArrowBackIos>前に戻る </div></div>
                <div className={classes.tittleIcon}>パスワード再設定</div>
                {(successOpen || errorOpen) && < div className={classes.errorAlert}>
                    < div style={{ width: VW(400) }}>
                        <Common_successAlert
                            open={successOpen}
                            setSuccessOpen={setSuccessOpen}
                            successContent={successContent}
                        />
                        <Common_errorAlert
                            open={errorOpen}
                            setRrrorOpen={setRrrorOpen}
                            errorContent={errorContent}
                        />
                    </div>
                </div>}
                <div className={classes.annotateText}>
                    <div>{state} に認証コードを送信しました。認証コードと新しいパスワードを入力してください。<br />※パスワードは8文字以上、英大文字、英小文字、数字をそれぞれ最低1つずつ含める必要があります。</div>
                </div>
                <div className={classes.label}>
                    <div style={{ width: VW(400), textAlign: 'left', marginBottom: VH(8), fontSize: VW(14) }}>認証コード <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                </div>
                <div className={classes.text} >
                    <form autoComplete="off">
                        <TextField
                            sx={{ width: VW(400) }}
                            name="code"
                            placeholder="６桁の認証コードを入力してください"
                            onChange={(event) => {
                                setCode(event.target.value)
                            }}
                            value={code}
                            autoComplete="off"
                            onFocus={(event) => { event.target.type = "text" }}
                            InputProps={{
                                className: classes.input,
                                classes: {
                                    input: classes.inputContent
                                }

                            }}
                        />
                    </form>
                </div>
                <div className={classes.sendAgainLink}>
                    <div>
                        <div onClick={sendCodeAgain}>認証コードを再送信する</div>
                    </div>
                </div>
                <div className={classes.label}>
                    <div style={{ width: VW(400), textAlign: 'left', marginBottom: VH(8), fontSize: VW(14) }}>新しいパスワード <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                </div>
                <div className={classes.text} >
                    {/* <form autoComplete="off"> */}
                    <TextField
                        sx={{ width: VW(400) }}
                        type={showPasswordA ? 'password' : 'text'}
                        placeholder="パスワードを入力してください"
                        onChange={(event) => { setNewPasswordA(event.target.value) }}
                        value={newPasswordA}
                        // autoComplete='off'
                        InputProps={{
                            className: classes.input,
                            endAdornment: (
                                <IconButton
                                    onClick={handleClickShowPasswordA}
                                    onMouseDown={handleMouseDownPasswordA}
                                    edge="end"
                                >
                                    {showPasswordA ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            ),
                            classes: {
                                input: classes.inputContent
                            }
                        }}
                    />
                    {/* </form> */}
                </div>
                <div className={classes.label}>
                    <div style={{ width: VW(400), textAlign: 'left', marginBottom: VH(8), fontSize: VW(14) }}>新しいパスワード（確認用） <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                </div>
                <div className={classes.text} >
                    {/* <form autoComplete="off"> */}
                    <TextField
                        type="text"
                        name="username"
                        autoComplete="username"
                        sx={{ position: "absolute", zIndex: -100000 }}
                        value={email}

                    />

                    <TextField
                        sx={{ width: VW(400) }}
                        type={showPasswordB ? 'password' : 'text'}
                        placeholder="パスワードを入力してください"
                        onChange={(event) => { setNewPasswordB(event.target.value) }}
                        value={newPasswordB}
                        // autoComplete='off'
                        InputProps={{
                            className: classes.input,
                            endAdornment: (
                                <IconButton
                                    onClick={handleClickShowPasswordB}
                                    onMouseDown={handleMouseDownPasswordB}
                                    edge="end"
                                >
                                    {showPasswordB ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            ),
                            classes: {
                                input: classes.inputContent
                            }
                        }}
                    />
                    {/* </form> */}
                </div>
                <Button
                    className={classes.loginButton}
                    variant="contained"
                    sx={{ width: VW(400), height: VH(48) }}
                    onClick={toChangePassword}
                >パスワード再設定
                </Button>
            </div>
        </div >
    )
}

ChangePassword.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(ChangePassword);