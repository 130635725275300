import React, { useEffect, useState, useRef } from "react";
import {
    Select,
    MenuItem,
    TablePagination
} from "@mui/material";
import log from "../../componets/function/log";
import { useNavigate } from 'react-router-dom'
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import { Input, Button } from 'antd'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Common_checkBoxTable from "../../componets/common/Common_checkBoxTable";
import Common_errorAlert from '../../componets/common/Common_errorAlert'
import { ClearRounded } from "@mui/icons-material"
import Common_successAlert from "../../componets/common/Common_successAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import AddIcon from '@mui/icons-material/Add';
import Navigation_bar_router from "../../componets/common/Navigation_bar_router";
import Navigation_bar_title from "../../componets/common/Navigation_bar_title";
import SearchIcon from '@mui/icons-material/Search';
import Project_delete from "../../componets/dialog/Project_delete";
import Project_add from "../../componets/dialog/Project_add";
import { ApiAdminProjectList } from '../../network/api'
import dic from "../../componets/function/utc";



const styles = (theme) => ({
    ground: {
        width: '100%',
        userSelect: 'none',
        position: 'relative',
        padding: '48px 24px',
    },
    retrievalBlock: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#F5F5F5',
        // paddingLeft: VW(16),
        paddingRight: VW(16),
        paddingTop: VH(16),
        '& > Input': {
            width: VW(874),
            height: VH(40),
            fontSize: VW(16)
        },
        '& > Button': {
            width: VW(120),
            height: VH(40),
            fontSize: VW(16),
            fontSize: VW(16),
            backgroundColor: theme.palette.primary.main,
        }
    },
    inputTitle: {
        fontSize: VW(14),
        width: VW(100),
        maxWidth: VW(85),
        textAlign: 'right',
        marginRight: VW(16)
    },
    querySelect: {
        width: '16%',
        height: VH(40),
        fontSize: VW(16),
        backgroundColor: '#FFF'
    },
    AddDeleteBlock: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: VH(24),
        '& > Button': {
            marginLeft: VW(8),
            width: VW(120),
            height: VH(40),
            fontSize: VW(16),
        },
    },
});



function ProjectManagement(props) {
    const {
        classes,
        setSelectFlag,
    } = props



    useEffect(() => {
        setSelectFlag(routerNames.ProjectManagement)
    }, [setSelectFlag])



    const navigate = useNavigate()

    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')

    // 検索
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  キーワード input
    const [keywordSearch, setKeywordSearch] = useState('')
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  招待 select 
    const [administratorFlag, setAdministratorFlag] = useState('0')
    const selectAdministratorFlag = (e) => {
        setAdministratorFlag(e.target.value);
    }
    const [administratorFlagOption, setAdministratorFlagOption] = useState([
        { value: '0', label: 'すべて' },
        { value: '1', label: '未招待' },
        { value: '2', label: '招待済（招待のみで登録してない）' },
        { value: '3', label: '登録済' }
    ])



    // Common_checkBoxTable
    const [titleList, setTitleList] = useState([
        { titleIcon: '↓', name: '店舗CD', field: 'project_id', width: '13.4199134199134%' },
        { titleIcon: '↓', name: '店舗名', field: 'project_name', width: '25.1082251082251%', canClick: true },
        { titleIcon: '↓', name: '招待', field: 'login_status', width: '6.0606060606061%', paddingLeft: '0.3vw' },
        { titleIcon: '', name: '管理者', field: 'representative', width: '19.9134199134199%' },
        { titleIcon: '', name: 'メールアドレス', field: 'email', width: '25.974025974026%' }
    ])
    const [bodyList, setBodyList] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  update
    const getItemData = (item) => {
        log(item)
        navigate(routerNames.ProjectManagementDetail, { state: item })
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete
    const [deleteData, setDeleteData] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  sort
    const [count, setCount] = useState(0)
    const pagingFlag = useRef('project_id')
    const pagingDirection = useRef('↑')
    const toSort = (item) => {
        log(item)
        pagingFlag.current = item.field
        pagingDirection.current = item.titleIcon
        titleList.map(titleItem => {
            if (titleItem.name === item.name) {
                titleItem.titleIcon = item.titleIcon === '↓' ? '↑' : '↓'
                titleItem.checked = true
            } else {
                titleItem.checked = false
                if (titleItem.field === 'representative' || titleItem.field === 'email') {
                    titleItem.titleIcon = ''
                } else {
                    titleItem.titleIcon = '↓'
                }
            }
        })
        setTitleList([...titleList])
        getList()
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  paging
    const [page, setPage] = useState(localStorage.getItem('tenant_page') ? Number(localStorage.getItem('tenant_page')) : 0);
    const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('tenant_rowsPerPage') ? Number(localStorage.getItem('tenant_rowsPerPage')) : 50);
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        localStorage.setItem('tenant_page', String(newPage))
    };
    const handleChangeRowsPerPage = (event) => {
        localStorage.setItem('tenant_rowsPerPage', String(event.target.value))
        setRowsPerPage(event.target.value);
        setPage(0);
        localStorage.setItem('tenant_page', '0')
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  checkbox check
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);



    // list api
    const getList = (errorEmail) => {
        if (localStorage.getItem('deleteFlag')) {
            setSuccessContent('削除が完了しました。')
            setSuccessOpen(true)
            localStorage.removeItem('deleteFlag')
        }
        let params = {
            query_content: encodeURIComponent(keywordSearch),
            loginStatus: administratorFlag == '0' ? '' : administratorFlag,
            pageNum: page + 1,
            pageLimit: rowsPerPage,
            sortKey: pagingFlag.current,
            sortValue: pagingDirection.current === '↓' ? 'asc' : 'desc'
        }
        ApiAdminProjectList(params).then(res => {
            let resData = res.data
            if (res.status === 200) {
                log(resData)
                setRrrorOpen(false)
                setCount(resData.count)
                resData.data.map(item => {
                    item.login_status = item.login_status === '3' ? 'correct' : item.login_status === '2' ? 'wrong' : ''
                    item.representative = (item.project_manager.length > 0 ? item.project_manager[0].first_name + item.project_manager[0].last_name : '') + (item.project_manager.length > 1 ? '（他' + (item.project_manager.length - 1) + '名）' : '')
                    item.email = item.project_manager.length > 0 ? item.project_manager[0].email : ''
                })
                setBodyList([...resData.data])
                if (errorEmail === 'add') {
                    setSuccessContent('登録が完了しました。')
                    setSuccessOpen(true)
                }
                if (errorEmail === 'delete') {
                    setSuccessContent('削除が完了しました。')
                    setSuccessOpen(true)
                }
                setDeleteData([])
                setIndeterminate(false)
                setCheckAll(false)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }



    // dialog
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteOpen(true)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  add dialog
    const [addOpen, setAddOpen] = useState(false)
    const addOnClose = () => {
        setAddOpen(false)
    }
    const openAddDialog = () => {
        setAddOpen(true)
    }
    const [administratorList, setAdministratorList] = useState([
        { name: '', pseudonym: '', email: '' },
        { name: '', pseudonym: '', email: '' },
        { name: '', pseudonym: '', email: '' }
    ])
    const [customerList, setCustomerList] = useState([undefined])


    // get list
    useEffect(() => {
        getList()
    }, [page, rowsPerPage])

    return (
        <div className={classes.ground}>
            <Navigation_bar_router main_name='店舗一覧' />
            <Navigation_bar_title title_name='店舗一覧' bottom="12px" />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
            />
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
            />
            <div style={{ marginTop: VH(12) }}>
                <div className={classes.retrievalBlock}>
                    <div className={classes.inputTitle}>キーワード</div>
                    <Input
                        placeholder='キーワードを入力してください'
                        value={keywordSearch}
                        style={{
                            // width: VW(874),
                            height: VH(40),
                            fontSize: VW(16)
                        }}
                        onChange={(event) => { setKeywordSearch(event.target.value) }}
                        suffix={
                            (
                                <ClearRounded className={classes.iconContainer} style={{ display: keywordSearch ? '' : 'none' }} onClick={(e) => {
                                    setKeywordSearch('')
                                }}></ClearRounded>
                            )
                        }
                    />
                </div>
                <div className={classes.retrievalBlock} style={{ paddingBottom: VH(16) }}>
                    <div className={classes.inputTitle}>&emsp;&emsp;&emsp;招待</div>
                    <Select
                        className={classes.querySelect}
                        value={administratorFlag}
                        label=""
                        onChange={selectAdministratorFlag}
                    >
                        {administratorFlagOption.map((item, index) => {
                            return (
                                <MenuItem style={{ fontSize: VW(16) }} key={index} value={item.value}>{item.label}</MenuItem>
                            )
                        })}
                    </Select>
                    <Button
                        type="primary"
                        icon={<SearchIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                        style={{ position: 'absolute', right: VW(16), fontWeight: 'bold' }}
                        onClick={getList}
                    >検索</Button>
                </div>
            </div>
            <div className={classes.AddDeleteBlock}>
                {localStorage.getItem('authority_id') == 2 &&
                    <Button
                        style={{
                            padding: 0,
                            color: deleteData.length === 0 ? '' : '#E60024'
                        }}
                        type="text"
                        danger
                        icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                        disabled={deleteData.length === 0}
                        onClick={openDeleteDialog}
                    >削除</Button>
                }
                <Button
                    style={{
                        padding: 0,
                        fontWeight: 'bold',
                        backgroundColor: '#0052CC'
                    }}
                    type="primary"
                    icon={<AddIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />}
                    onClick={openAddDialog}
                >登録</Button>
            </div>
            <Project_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={deleteData}
                setDeleteData={setDeleteData}
                getList={getList}
                setIndeterminate={setIndeterminate}
                setCheckAll={setCheckAll}
            />
            <Project_add
                open={addOpen}
                onClose={addOnClose}
                administratorList={administratorList}
                setAdministratorList={setAdministratorList}
                getList={getList}
                customerList={customerList}
                setCustomerList={setCustomerList}
            />
            <TablePagination
                style={{ fontSize: VW(12) }}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[20, 50, 100]}
                labelRowsPerPage='表示件数:'
                className={classes.selectLabel}
                classes={{
                    toolbar: classes.toolbar
                }}
            />
            <div className={classes.tableBlock}>
                <Common_checkBoxTable
                    titleList={titleList}
                    bodyList={bodyList}
                    setBodyList={setBodyList}
                    getItemData={getItemData}
                    setCheckList={setDeleteData}
                    toSort={toSort}
                    indeterminate={indeterminate}
                    setIndeterminate={setIndeterminate}
                    checkAll={checkAll}
                    setCheckAll={setCheckAll}
                />
            </div>
        </div >
    )
}

ProjectManagement.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(ProjectManagement);