import {
    Checkbox
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { VH, VW } from "../../componets/function/sizeAdapter";
import CorrectIcon from "../icon/CorrectIcon";
import WrongIcon from "../icon/WrongIcon";


const styles = (theme) => ({
    checkBoxTable: {
        width: '100%',
        height: '100%',
        fontSize: VW(16),
    },
    titleBlock: {
        width: '100%',
        height: VH(56),
        borderBottom: '1px solid #DDDDDD',
        backgroundColor: '#F5F5F5',
        display: 'flex',
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold'
        },
        '& > div:nth-child(1)': {
            paddingRight: VW(11.5),
            paddingLeft: VW(19.5)
        },
        '& > div > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            userSelect: 'none'
        },
        // '& > div > div > span':{
        //     height:'100%',
        //     display:'flex',
        //     alignItems:'center',
        //     marginLeft:,
        //     fontSize:,
        //     color:'#AAA'
        // },
    },
    bodyBlock: {
        width: '100%',
        height: VH(48),
        borderBottom: '1px solid #DDDDDD',
        display: 'flex',
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& > div:nth-child(1)': {
            paddingRight: VW(11.5),
            paddingLeft: VW(19.5)
        }
    },
    checkBox: {
        '&:hover': {
            backgroundColor: 'transparent !important'
        },
        padding: VW(0)
    },
    sendMessangeButton: {
        width: VW(63),
        height: VH(32),
        color: '#FFF',
        backgroundColor: theme.palette.primary.main,
        fontSize: VW(14),
        fontWeight: 'bold',
    }
});

function Common_checkBoxTable(props) {
    const {
        classes,
        titleList,
        bodyList,
        setBodyList,
        getItemData,
        setCheckList,
        toSort,
        indeterminate,
        setIndeterminate,
        checkAll,
        setCheckAll,
        allWidth,
    } = props


    const changeBodyCheckd = (list) => {
        let checkTotal = 0
        let checkList = []
        bodyList.map(item => {
            if (item === list) {
                if (item.checked) {
                    item.checked = false
                } else {
                    item.checked = true
                    checkTotal = checkTotal + 1
                    checkList.push(item)
                }
            } else {
                if (item.checked) {
                    checkTotal = checkTotal + 1
                    checkList.push(item)
                }
            }
        })
        setCheckList([...checkList])
        setBodyList([...bodyList])
        if (checkTotal === bodyList.length) {
            setCheckAll(true)
            setIndeterminate(false)
        } else if (checkTotal < bodyList.length && checkTotal != 0) {
            setCheckAll(false)
            setIndeterminate(true)
        } else {
            setCheckAll(false)
            setIndeterminate(false)
        }
    };
    const onCheckAllChange = (e) => {
        setIndeterminate(false);
        setCheckAll(e.target.checked);
        bodyList.map(item => {
            if (e.target.checked) {
                item.checked = true
                setCheckList([...bodyList])
            } else {
                item.checked = false
                setCheckList([])
            }
        })
    };

    const returnFc = () => {
        return
    }



    return (
        <div className={classes.checkBoxTable} style={{ overflowX: allWidth ? 'scroll' : '' }}>
            <div style={{ width: allWidth ? allWidth : '100%' }}>
                <div className={classes.titleBlock}>
                    <div>
                        <Checkbox style={{ backgroundColor: '#FFF' }} key={checkAll} indeterminate={indeterminate} checked={checkAll} onChange={onCheckAllChange} className={classes.checkBox}
                            sx={{
                                color: "#DDDDDD",
                                "& .MuiSvgIcon-root": {
                                    fontSize: VW(21)
                                },
                                '&:hover': {
                                    backgroundColor: 'transparent !important'
                                }
                            }}
                        />
                    </div>
                    {titleList.map((item, index) => {
                        return (
                            <div key={index} style={{ width: item.width, whiteSpace: 'pre-line', display: 'flex', justifyContent: item.center }}>
                                <div onClick={() => { item.titleIcon === '' ? returnFc() : toSort(item) }}>{item.name}</div>
                            </div>
                        )
                    })}
                </div>
                {bodyList.map((item, index) => {
                    return (
                        <div className={classes.bodyBlock} key={index} style={{ height: item.file_display_flg == 'none' ? 0 : '', borderBottom: item.file_display_flg == 'none' ? 0 : '' }}>
                            <div>
                                <Checkbox
                                    className={classes.checkBox}
                                    sx={{
                                        color: "#DDDDDD",
                                        "& .MuiSvgIcon-root": {
                                            fontSize: VW(21)
                                        },
                                        '&:hover': {
                                            backgroundColor: 'transparent !important'
                                        },
                                    }}
                                    key={item.checked}
                                    checked={item.checked}
                                    onChange={() => { changeBodyCheckd(item) }}
                                />
                            </div>
                            {titleList.map((titleItem, titleIndex) => {
                                return (
                                    <div
                                        key={titleIndex}
                                        style={{
                                            width: titleItem.width,
                                            color: titleItem.canClick ? '#0052CC' : '',
                                            cursor: titleItem.canClick ? 'pointer' : '',
                                            paddingLeft: titleItem.paddingLeft ? titleItem.paddingLeft : '',
                                            paddingRight: titleItem.center == 'center' ? 0 : VW(16),
                                            justifyContent: titleItem.center,
                                        }}
                                        onClick={() => { { titleItem.canClick ? getItemData(item) : returnFc() } }}
                                    >
                                        {
                                            item[titleItem.field] === 'correct' ? <CorrectIcon /> :
                                                item[titleItem.field] === 'wrong' ? <WrongIcon /> :
                                                    <p style={{ userSelect: 'none' }}>{item[titleItem.field]}</p>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>

        </div>
    )
}

Common_checkBoxTable.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Common_checkBoxTable);