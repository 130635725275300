import React, { useEffect, useState, useRef } from "react";
import {
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../function/sizeAdapter"
import { ArrowForwardIos } from "@mui/icons-material";


const styles = (theme) => ({
    navigationBar: {
        width: '100%',
        height: VH(32),
        fontSize: VW(14),
        display: 'flex',
        alignItems: 'center',
        paddingLeft: VW(8),
        position: 'absolute',
        top: 0,
        left: 0,
    }
});




function Navigation_bar_router(props) {
    const {
        classes,
        main_name,
        secondary_name
    } = props

    const returnFc = () => {
        return
    }


    return (
        <div className={classes.navigationBar}>
            <span style={{ cursor: 'pointer' }} onClick={() => { secondary_name ? window.history.back() : returnFc() }}>{main_name}</span>
            {secondary_name && <ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>}
            {secondary_name ? secondary_name : ''}
        </div>

    )
}

Navigation_bar_router.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Navigation_bar_router);