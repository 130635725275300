import { message } from "antd";
import axios from "axios";
import { refreshToken } from "./cognito";

var isRefreshing = false
var requests = []


const dealReturn = (response) => {
  //
  if (!response) {
    return "接続中断";
  }
  const { status, data, config } = response;
  if (status === 401) {
    if (data.message == 'The incoming token has expired') {
      if (!isRefreshing) {
        isRefreshing = true
        return refreshToken()
          .then((sessionData) => {
            const accessToken = sessionData.data.idToken.jwtToken;
            localStorage.setItem(
              "access_token",
              accessToken
            );
            config.headers["Authorization"] = "Bearer " + accessToken;

            requests.forEach((cb) => cb(accessToken));
            requests = [];
            isRefreshing = false;
            return axios(config);
          })
          .catch(() => {
            localStorage.setItem("access_token", "");
            localStorage.setItem("refresh_token", "");
            isRefreshing = false;
            requests = [];
          });
      } else {

        // the token is being refreshed and will return a promise that does not execute resolve
        return new Promise((resolve) => {
          // put resolve in the queue, use a function to save it, and execute it directly when token is refreshed
          requests.push((token) => {
            // config.baseURL = ''
            config.headers.Authorization = token;
            resolve(axios(config))
          })
        })
      }
    } else {
      window.location.href = '/login'
    }

  }
  return response;
};

export default dealReturn;
