import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input } from 'antd';
import log from "../function/log";
import { ApiCompanyMemberDelete } from '../../network/api'
import Common_errorAlert from "../common/Common_errorAlert";
import HeaderBarIcon from "../icon/HeaderBarIcon";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(26)
    },
    headerBlock: {
        display: 'flex',
        width: '100%',
        height: VH(56),
        marginTop: VH(16),
        backgroundColor: '#F8F8F8',
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: '#DDDDDD',
        '& > div': {
            height: '100%',
            fontWeight: 'bold',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.26vw'
        },
        '& > div:nth-child(1)': {
            width: '16.5%'
        },
        '& > div:nth-child(2)': {
            width: '32.5%'
        },
        '& > div:nth-child(3)': {
            width: '17%'
        },
        '& > div:nth-child(4)': {
            width: '14%'
        },
        '& > div:nth-child(5)': {
            width: '16%'
        },
    },
    scrollBlock: {
        width: '100%',
        maxHeight: VH(224),
        overflowY: 'scroll',
        overflowY: 'overlay'
    },
    bodyBlock: {
        display: 'flex',
        width: '100%',
        height: VH(56),
        borderBottom: '1px solid #DDD',
        '& > div': {
            height: '100%',
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.26vw',
            overflow: 'hidden'
        },
        '& > div:nth-child(1)': {
            width: '16.5%'
        },
        '& > div:nth-child(2)': {
            width: '32.5%'
        },
        '& > div:nth-child(3)': {
            width: '17%'
        },
        '& > div:nth-child(4)': {
            width: '14%'
        },
        '& > div:nth-child(5)': {
            width: '16%'
        },
        '& > div > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: '#E60024',
            marginLeft: VW(16)
        }
    }
});

function Member_delete(props) {
    const {
        classes,
        open,
        onClose,
        deleteData,
        getList,
        setDeleteData,
        setIndeterminate,
        setCheckAll,
        historyFlag
    } = props


    useEffect(() => {
        if (open) {
            log(deleteData)
        } else {
            setTitleFlag(false)
            setRrrorOpen(false)
        }
    }, [open])


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')


    // title text flag
    const [titleFlag, setTitleFlag] = useState(false)


    // delete api
    const toDelete = () => {
        if (!titleFlag) {
            setTitleFlag(true)
        } else {
            let deleteList = []
            deleteData.map(item => {
                deleteList.push({
                    user_authority_id: item.user_authority_id
                    // user_id: item.user_id,
                    // email: item.email,
                    // authority_id: item.authority_id
                })
            })
            console.log(deleteList);
            ApiCompanyMemberDelete(deleteList).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    if (historyFlag) {
                        onClose()
                        window.history.back()
                        localStorage.setItem('member_deleteFlag', true)
                    } else {
                        getList('delete')
                        onClose()
                        setDeleteData([])
                        setIndeterminate(false)
                        setCheckAll(false)
                    }
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div className={classes.tableTitle}>
                <HeaderBarIcon />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>メンバーの削除</div>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
            />
            {titleFlag ? <div style={{ color: '#E60024', fontSize: VW(16) }}>以下のメンバーを削除します。<br /> 削除されたデータは復元できませんが、本当に削除しますか？</div> : <div style={{ fontSize: VW(16) }}>以下のメンバーを削除します。</div>}
            <div className={classes.headerBlock}>
                <div>氏名</div>
                <div>メールアドレス</div>
                <div>所属</div>
                <div>権限</div>
                <div>ステータス</div>
            </div>
            <div className={classes.scrollBlock}>
                {deleteData && deleteData.map((item, index) => {
                    return (
                        <div className={classes.bodyBlock} key={index}>
                            <div>
                                <p>{item.allName}</p>
                            </div>
                            <div>
                                <p>{item.email}</p>
                            </div>
                            <div>
                                <p>{item.affiliation}</p>
                            </div>
                            <div>
                                <p>{item.authority_name}</p>
                            </div>
                            <div>
                                <p>{item.status_name}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={classes.buttonGroup}>
                <Button onClick={onClose}>キャンセル</Button>
                <Button danger onClick={toDelete} disabled={deleteData.length == 0}>削除</Button>
            </div>
        </Modal>
    )
}

Member_delete.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Member_delete);