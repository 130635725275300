import React, { useEffect, useState, useRef } from "react";
import {
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { VW, VH } from "../function/sizeAdapter"

const styles = (theme) => ({
});

function Red_star(props) {
    const {
        classes,
    } = props



    return (
        <span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span>
    )
}

Red_star.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Red_star);