
import { withStyles } from "@mui/styles";
import React from "react";
import { Route, Routes } from "react-router-dom";
import TenantManagement from "../home/tenantManagement/TenantManagement";
import TenantManagementDetail from "../home/tenantManagement/TenantManagementDetail";
import MemberManagement from "../home/memberManagement/MemberManagement";
import MemberManagementDetail from "../home/memberManagement/MemberManagementDetail";
import MasterManagement from "../home/masterManagement/MasterManagement";
import ProjectManagement from "../home/projectManagement/ProjectManagement";
import ProjectManagementDetail from "../home/projectManagement/ProjectManagementDetail";
import PersonalData from "../home/personalData/PersonalData";
import PersonalDataDetail from "../home/personalData/PersonalDataDetail";
import ProjectDetail from "../home/projectDetail/ProjectDetail";
import Empty from "../home/emptyManagement/EmptyManagement"

const styles = (theme) => ({

});

function MainRouters(props) {
    const {
        classes,
        setSelectFlag,
        userObject,
        setUserObject
    } = props


    return (
        <Routes>
            <Route path="*" element={<TenantManagement setSelectFlag={setSelectFlag} />} />
            <Route path="personalData" element={<PersonalData setSelectFlag={setSelectFlag} setUserObject={setUserObject} />} />
            <Route path="personalData/personalDataDetail" element={<PersonalDataDetail setSelectFlag={setSelectFlag} />} />
            <Route path="TenantManagement" element={<TenantManagement setSelectFlag={setSelectFlag} />} />
            <Route path="TenantManagement/TenantManagementDetail" element={<TenantManagementDetail setSelectFlag={setSelectFlag} />} />
            <Route path="MemberManagement" element={<MemberManagement setSelectFlag={setSelectFlag} />} />
            <Route path="MemberManagement/MemberManagementDetail" element={<MemberManagementDetail setSelectFlag={setSelectFlag} />} />
            <Route path="MasterManagement" element={<MasterManagement setSelectFlag={setSelectFlag} />} />
            <Route path="ProjectManagement" element={<ProjectManagement setSelectFlag={setSelectFlag} />} />
            <Route path="ProjectManagement/ProjectManagementDetail" element={<ProjectManagementDetail setSelectFlag={setSelectFlag} />} />
            <Route path="ProjectDetail" element={<ProjectDetail setSelectFlag={setSelectFlag} />} />
            <Route path="Empty" element={<Empty setSelectFlag={setSelectFlag} />} />

        </Routes>
    )
}


MainRouters.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(MainRouters);