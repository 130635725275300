

const routerNames = {
    Login: 'login',
    NewPassword: 'newPassword',
    SendCode: 'sendCode',
    ChangePassword: '/sendCode/changePassword',
    ChangeOK: '/changeOk',
    Home: 'home',
    PersonalData: '/home/personalData',
    PersonalDataDetail: '/home/personalData/personalDataDetail',
    TenantManagement: '/home/tenantManagement',
    TenantManagementDetail: '/home/tenantManagement/tenantManagementDetail',
    MemberManagement: '/home/memberManagement',
    MemberManagementDetail: '/home/memberManagement/memberManagementDetail',
    MasterManagement: '/home/masterManagement',
    ProjectManagement: '/home/projectManagement',
    ProjectManagementDetail: '/home/projectManagement/projectManagementDetail',
    ProjectDetail: '/home/projectDetail',
    Empty: '/home/empty'
}


const successMessage = {
    codeSendSuccess: '認証コードの送信に成功しました。'
}


const errorMessage = {
    namePasswordFormatError: 'ユーザー名またはパスワードが誤っています。',
    nameFormatError: 'ユーザー名を正しく入力してください。',
    nameEmptyError: 'ユーザー名を入力してください。',
    passwordFormatError: 'パスワードは8文字以上、英大文字、英小文字、数字をそれぞれ最低1つずつ含める必要があります。',
    passwordNull: 'パスワードを入力してください。',
    namePasswordDif: '新しいパスワード（確認用）が新しいパスワードと一致しません。',
    storeNameNull: '店舗名を入力してください。',
    tenantNameNull: 'テナント名を入力してください。',
    startEndDayNull: '終了日は開始日よりも先の日付に設定してください。',
    administratorNull: '管理者を追加する場合には、管理者名とメールアドレスを漏れなく入力してください。',
    ownerNameNull: ' オーナー名を入力してください。',
    emailFormatError: 'メールアドレスを正しく入力してください。',
    zipCodeFormatError: '郵便番号は、000-0000の形式で入力してください。',
    phoneFormatError: '電話番号は、0から9の数字と、+, -,  ()のみを利用して入力してください。',
    E0037: 'クライアント契約中の商品のため削除できません。',
    E0007: 'パスワードには半角英数字、半角記号のみ含めることができます。',
    E0004: '新しいパスワードを入力してください。',
    E0010: '認証コードを入力してください。',
    E0042: 'タイトルは90文字以内で入力してください。',
    E0043: 'お知らせ本文は500文字以内で入力してください。',
    E0044: 'お知らせが削除済みのため参照できません。',
    E0045: 'お知らせが削除済みのため編集できません。',
    noticeDateNull: 'お知らせ配信期間を入力してください。',
    E0046: 'リマインダー本文は500文字以内で入力してください。',
    E0047: 'リマインダーが削除済みのため参照できません。',
    E0048: 'リマインダーが削除済みのため編集できません。',
    reminderDateNull: 'リマインダー配信期間を入力してください。',
    projectNameNull: 'プロジェクト名を入力してください。',


    E00001: 'ユーザー名またはパスワードに誤りがあります',
    E00002: '認証コードが一致しません。',
    E00003: '認証コードの有効期限が切れています。再発行してください。',
    E00004: '送信回数が制限を超えたので、1時間後にお試しください。',
    E00005: '認証コードシステムエラーが発生しました。少し時間を置いてから再実行してください。',
    E00006: '適切なロールが付与されていません。'
}



const formatCheck = {
    Email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    Code: /^\d{6}$/,
    Password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{8,99}$/,
    Phone: /^[0-9＋+() ー（） ^\uFF10-\uFF19 -]{1,15}$/,
    ZipCode: /^[0-9]{3}-[0-9]{4}$/,
    UserName: /[^\x00-\xFF]/
}



export {
    errorMessage,
    formatCheck, routerNames, successMessage
}
